#button{
    color: #000;
    margin-right: 5px;
    border-radius: 25px;
    transition: 0.3s ease-in-out !important
}

#text{
    color: #fff;
   
}

/* #button:hover{
    color: #000;
    background: linear-gradient(45deg,#2ed8b6,#59e0c5);
    box-shadow: 0 0 50px 50px inset, 0 0 10px #333;
    transition: 0.3s ease-in-out;
}

#button #text:hover{
    color: #000;  
    background: linear-gradient(45deg,#2ed8b6,#59e0c5);
    box-shadow: 0 0 10px #333;
    transition: 0.3s ease-in-out;
} */

