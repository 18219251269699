#dropdown{
    position: absolute;
    z-index: 999;
}

.dropdown .dropdown-item{
    background-color: rgb(239, 240, 236);
    max-width: 94%;
    border-radius: 5px;  
}

.dropdown-row{
    border: 1px solid rgb(233, 229, 229);
    border-radius: 5px;
    padding: 10px 10px 10px;
    border: px solid black;
}

.dropdown-row:hover{
    cursor: pointer;
    border: 1px solid black;
    background-color: white;
}

