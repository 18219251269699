
/**************************** SMALL PC VIEW CHANGES ********************************/
@media(max-width:920px){

    /* NAVBAR */
    /* navbar logo */
    #hire-navbar-logo-default{
    margin-top:0.1rem;
    height:6.5rem;
    width:6.5rem;
    }
    /*menu icon */
    #navbar-menu-icon{
    margin-left: 4rem;
    margin-top:1rem;
    height:4rem;
    width:4rem;
    }


/* MAIN SECTION */
.hire-main-section{
    display: flex;
    flex-direction: right;
    width: 100vw;
    font-size: 78%;
}
/* LEFT SIDEBAR */
.hire-left-sidebar{
    width:40vw;
    box-shadow: 2px 1px 2px #777c86;
    height: 100vh;
    display: none;
    animation: fade-in 2s linear forwards;
    position: fixed;
    z-index: 2;
    background-color: #fafafa;
}
/* MAIN PANEL */
.hire-main-panel{
    width:100vw;
    background-color: #fafafa;
    height: 2000vh;
    overflow-y: scroll;
    scroll-behavior: auto;
}
/* MAIN PANEL EXT */
.hire-main-panel-ext{
    width:100vw;
    background-color: #fafafa;
    height: 2000vh;
    overflow-y: scroll;
    margin-top: 9vh;
}
/* RIGHT SIDEBAR */
.hire-right-sidebar{
    width:40vw;
    box-shadow: 2px 1px 2px #777c86;
    height: 100vh;
    display: none;
    animation: fade-in 2s linear forwards;
    position: fixed;
    z-index: 3;
    background-color: #fafafa;
}



/* FORM PANEL */
.hire-form-container{
    font-size: 65%;
}
/* Form Elements */
.hire-form-list{
    display: flex;
    flex-direction: row;
}
.hire-form-group-large{
    width:94%;
    margin-left: 1%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
}
.hire-form-group-large .hire-form-group-left{
    width:15%;
    text-align: left;
}
.hire-form-group-large .hire-form-group-right{
    width:83%;
}
.hire-form-group-small{
    width: 45%;
    margin-left: 0%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
}

    }   
