
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@200;300;400;500;600&family=Nunito:ital@1&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&family=Roboto:ital@1&family=Secular+One&display=swap');

body{
    font-size: 1rem;
}

.bth-features-section{
width:100%;
display: flex;
flex-direction: row;
padding: 2rem;
}
.bth-features-left{
    width:50%;
    text-align: left;
    padding: 2rem;
}
.bth-features-right{
    width:50%;
}
.bth-features-left h2{
    font-family: 'League Spartan', sans-serif;
    font-weight: bold;
    font-size: 3rem;
    text-align: left-justify;
}
.bth-features-left p{
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4rem;
    text-align: left-justify;
}
.bth-features-elm-section{
    display: flex;
    flex-direction: row;
}
.bth-features-elm-section-1{
    width:50%;
}
.bth-features-elm-section-2{
    width:50%;
}

.bth-features-elm-card{
    width:90%;
    padding: 2rem;
    text-align: left;
   /* border-bottom: 0.4rem solid #10b74a;
    border-right: 0.4rem solid #10b74a; */
    box-shadow:2px 3px 3px 2px #10b74a;
    border-radius: 12px;
}


.bth-images-section-top h2{
    font-family: 'League Spartan', sans-serif;
    font-weight: bold;
    font-size: 3rem;
    text-align: left-justify;
}
.bth-images-section-top p{
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4rem;
    text-align: left-justify;
}

  /* Other Animations */

  /* Animation for changing heading text */
 /*h1 spa*/
 #change-txt-color::before { 
    content: "Start Now for Free"; 
    animation: animate ease-in-out infinite 4s ; 
    padding-left: 10px; 
} 
@keyframes animate { 
    0% {     content: "Manage Transactions"; } 
    50% {     content: "Multiple Businesses"; } 
    75% {   content: "Counters and Team"; } 
} 




  /* Small and Medium Devices */
  @media (max-width:900px){

    .bth-features-section{
        width:100vw;
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
        }
        .bth-features-left{
            width:100%;
            text-align: left;
            padding: 0.5rem;
        }
        .bth-features-right{
            width:100%;
        }
        .bth-features-left h2{
            font-size: 2.4rem;
        }
        .bth-features-left p{
            font-family: 'Open Sans', sans-serif;
            font-size: 1rem;
            text-align: left-justify;
        }

  }



