
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@200;300;400;500;600&family=Nunito:ital@1&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&family=Roboto:ital@1&family=Secular+One&display=swap');

body{
    font-size: 1rem;
}

/* HEADER */

.sv-header-container1{
    width:100%;
    display: flex;
    flex-direction: row;
    }

.sv-header-container{
width:100vw;
display: flex;
flex-direction: row;
}
.sv-header-left{
    width:50%;

}

.sv-header-left h1{
    font-family: 'League Spartan', sans-serif;
    font-weight: bold;
    font-size: 3.4rem;
    text-align: left;
}
.sv-header-left p{
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4rem;
    text-align: left;
}
.sv-header-right{
    width:50%;
}
.sv-searchbar{
    width:96%;
    margin-left: 2%;

}
.sv-searchbar select{
    padding:0.5rem;
    border:0.1rem solid #014d66;
    box-shadow: 2px 2px 3px 2px #b8b8b8;
    border-radius: 12px;
    width:60%;
}
.sv-searchbar button{
    background-color: #014d66;
    color:#ffffff;
    font-weight: bold;
    padding: 0.5rem;
    border-radius: 15px;
    box-shadow: 2px 2px 3px 2px #b8b8b8;
    width:25%;
    margin-left: 1rem;
    border:#ffffff;
}

/* SERVICES SECTION */
.sv-services-section{
    background-color: #f4f2f2;
    padding: 2rem;
}
.sv-services-section h2{
    font-family: 'League Spartan', sans-serif;
    font-weight: bold;
    font-size: 3rem;
    text-align: center;
    margin-top: 1rem;
}

/* FEATURES SECTION */
.sv-features-section{
    background-color: #ffffff;
    padding: 2rem;
}
.sv-features-section h2{
    font-family: 'League Spartan', sans-serif;
    font-weight: bold;
    font-size: 3rem;
    text-align: center;
    margin-top: 1rem;
}

/* TESTIMONIALS SECTION */
.sv-testimonials-section{
    background-color: #00444a;
    padding: 2rem;
    color:#ffffff;
}
.sv-testimonials-section h2{
    font-family: 'League Spartan', sans-serif;
    font-weight: bold;
    font-size: 2.4rem;
    text-align: center;
    margin-top: 1rem;
}

/* scrollbar */

.display-cards-custom ::-webkit-scrollbar {
    width: 5px;
  }
  

  .display-cards-custom ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .display-cards-custom ::-webkit-scrollbar-thumb {
    background: #1f60ba;
    border-radius: 5px;
  }


  /* Navbar Select */
  #nav-select:hover{
    font-weight: bold;

  }

  
  /* Small and Medium Devices */
  @media (max-width:900px){


/* HEADER */

.sv-header-container1{
    width:100%;
    display: flex;
    flex-direction: column;
    }

.sv-header-container{
    width:100vw;
    display: flex;
    flex-direction: column;
    }
    .sv-header-left{
        width:100%;
    
    }
    
    .sv-header-left h1{
        font-family: 'League Spartan', sans-serif;
        font-weight: bold;
        font-size: 2.5rem;
        text-align: left;
    }
    .sv-header-left p{
        font-family: 'Open Sans', sans-serif;
        font-size: 1rem;
        text-align: justify;
    }
    .sv-header-right{
        width:100%;
    }

  }


