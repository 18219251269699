
/**************************** CHANGES FOR PRINT VIEW ********************************/
@media(max-width:600px){

  
    .card-body p{
      font-size: 0.8rem !important;
    }
  
  
    @media print {
      .row {
        display: flex;
        flex-wrap: nowrap; /* Ensure no wrapping happens */
        justify-content: space-between; /* Distribute space between columns */
      }
      
      .col-md-4 {
        width: 33%; /* Ensure columns take 1/3 of the width */
        box-sizing: border-box;
      }
    
      .table {
        font-size: 10pt; /* Reduce the table font size for better fitting */
        width: 100%;
      }
    
      h3, h4 {
        font-size: 12pt; /* Ensure headers scale well in print */
      }
    
      hr {
        border: 1px solid #2d6cb5;
      }
    
      /* Adjust padding and margins for a clean print layout */
      .card {
        padding: 10px;
        margin: 0;
        border: none;
      }
    
      .card-body {
        padding: 10px;
      }
    
      .table td, .table th {
        padding: 5px;
      }
    
      /* Hide the ref and code elements during print */
      .ref, code {
        display: none;
      }
    }
  
}