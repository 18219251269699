
html{


}
/**************************** DEFAULT SCREEN SIZE 1200+ ********************************/

/*  NAVBAR  */
.hire-navbar{
    width:100vw;
    height:9vh;
    background-color: #fafafa;
    box-shadow: 2px 1px 2px #777c86;
    display: flex;
    flex-direction: row;
    position: fixed;
}
/* Navbar left */
.hire-navbar-left{
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: left;
}
/* logo */
.hire-navbar-logo{
align-items: left;
}
#hire-navbar-logo-default{
margin-top:0.1rem;
height:5rem;
width:5rem;
 }
 /*menu icon */
#navbar-menu-icon{
margin-left: 4rem;
margin-top:1rem;
height:3rem;
width:3rem;
}
/* Navbar Right */
.hire-navbar-right{
    width: 30%;
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;
}

/* MAIN SECTION */
.hire-main-section{
    display: flex;
    flex-direction: right;
    width: 100vw;
    font-size: 67%;
    
}
/* LEFT SIDEBAR */
.hire-left-sidebar{
    margin-top: 9vh;
    width:20vw;
    box-shadow: 2px 1px 2px #777c86;
    height: 400vh;
}
/* MAIN PANEL */
.hire-main-panel{
    width:60vw;
    background-color: #fafafa;
    height: 400vh;
  /*  z-index: -2;  */
    margin-top: 9vh;
}
/* MAIN PANEL EXT */
.hire-main-panel-ext{
    width:80vw;
    background-color: #fafafa;
    height: 400vh;
  /*  z-index: -2;  */
    margin-top: 9vh;
}
.hire-left-sidebar li{
   margin-left: 1rem;
   text-align: left;
   margin-top: 1rem;
}
.hire-left-sidebar li:hover {
    padding: 1rem;
    background-color: #013cb1;
    color: #fafafa;
    font-weight: bold;
 }


/* RIGHT SIDEBAR */
.hire-right-sidebar{
    margin-top: 9vh;
    width:20vw;
    box-shadow: 2px 1px 2px #777c86;
    height: 400vh;
    background-color: #fafafa;
    border-left: 0.2rem solid #777c86;
    overflow-y: scroll;
}

/* Lines */
#line-1{
    width: 94%;
    margin-left: 3%;
    background-color: #013cb1;
    padding: 0.1rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}
#line-2{
    width: 96%;
    margin-left: 2%;
    background-color: #013cb1;
    padding: 0.1rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}
#line-3{
    width: 98%;
    margin-left: 1%;
    background-color: #013cb1;
    padding: 0.1rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

/* FILTER FORM */
.filter-section{
    text-align: left;
    margin-left: 1rem;
}
.filter-heading{

}
.filter-form-1{
    width:96%;
    margin-left: 2%;
}
.filter-form-input1{
    padding: 0.5rem;
    border: 0.1rem solid #013cb1;
    box-shadow: 2px 1px 2px #777c86;
}
.filter-selection{
display: flex;
flex-direction: row;

}
.filter-selection-text{
width:70%;
text-align: left;
margin-left: 2rem;
}
.filter-selection-text label{
    font-size: 90%;
}
.filter-selection-check input[type="checkbox"]{
    border-color: #013cb1;
    border: 2rem solid #013cb1;
    height:2rem;
    width:2rem;
    
}
.filter-selection-check input[type="checkbox"]:checked{
    background-color: #013cb1;
}
.filter-form-bar{
    display: flex;
    flex-direction: row;
    width: 96%;
    margin-left: 2%;
    margin-top: 1rem;
}
.filter-form-text{
    width: 40%;
    text-align: left;
}
.filter-form-input{
    width: 55%;
}
.filter-form-input input[type="text"]{
    border: 0.1rem solid #013cb1;
    padding: 0.5rem;
    box-shadow: 2px 1px 2px #777c86;
    border-radius: 5px;
    width:90%;
    margin-left: 10%;
}
.filter-form-input select{
    border: 0.1rem solid #013cb1;
    padding: 0.5rem;
    box-shadow: 2px 1px 2px #777c86;
    border-radius: 5px;
    width:90%;
    margin-left: 10%;
}
.view-al{
    color:#013cb1;
    font-weight: bold;
    text-decoration: underline;
    font-size: 80%;
}
/* City Filter */
.filter-section-city-filter{
    margin-top: 1rem;
    width:96%;
    margin-left: 2%;
   /* border: 0.1rem solid #013cb1; 
    box-shadow: 2px 1px 2px #777c86; */
}


/*  MAIN PANEL */
/* SEARCHBAR */
.hire-search-bar{
width: 96%;
height: 2vh;
margin-left: 2%;
}

/* LIST PANEL */
.list-panel{
    width:96%;
    height:100%;
    margin-left: 2%;
    border: 0.1 rem solid #013cb1;
    border-radius: 10px;
    background-color: #ffffff;
    margin-top: 2rem;
    box-shadow: 2px 1px 2px #777c86;
    overflow-y: scroll;
}

/* Hire List */
.hire-list{
    margin-top: 2vh;
}
/* Hire List Card */
.hire-list-card{
    width:94%;
    margin-left: 3%;
    border: 0.1 rem solid #013cb1;
    border-radius: 10px;
    margin-top: 2rem;
    box-shadow: 1px 1px 2px 1px #021336;
    text-align: left;
    
}
.hire-list-card-top{
    margin-left: 2rem;
    display:flex;
    flex-direction: row;
}
.hire-list-card-top-l{
    margin-left: 1rem;
    width: 50%;

}
.hire-list-card-top-r{
    margin-right:1rem;
    font-size: 90%;
    text-align: right;
    width: 50%;
}
.hire-list-card-area{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.hire-list-card-area-l{
    width:70%;
    margin-bottom: 1rem;
}
.hire-list-card-area-r{
    width:30%;
    margin-bottom: 1rem;
}
.hire-list-card-salarycard{
    margin-bottom: 1rem;
    margin-left: 4rem;
    font-size: 80%;
    color:#8c8b94;
}
.hire-list-card-btn-list{
    margin-left: 3rem;
    display: flex;
    flex-direction: row;
}
.hire-list-card-function{
    color:#777c86;
    font-size: 80%;
    text-align: center;
    margin-right: 1rem;
    width:9rem;
    margin-left: 1rem;
    padding: 0.3rem;
    margin-top: 1rem;
}
.hire-list-card-function-component{
    display:flex;
    flex-direction:row;
    align-items: center;
}
.hire-list-card-function-component-l{
    width: 40%;
}
.hire-list-card-function-component-r{
    width: 60%;
    text-align: left;
}
.hire-list-card-function-component button{
    background-color: transparent;
    border: none;
}
.hire-list-card-function-component img{
    width:3rem;
    height:3rem;
}
.hire-list-card-badge{
    color:#777c86;
    font-size: 80%;
    text-align: center;
    margin-right: 1rem;
    background-color: #e1e1e2;
    width:70%;
    margin-left: 15%;
    padding: 0.3rem;
    border: 0.1 rem solid #949698;
    border-radius: 5px;
    margin-top: 1rem;
    box-shadow: 1px 1px 1px 1px #a4a4a4;
}
.hire-list-card-badge-component{
    display:flex;
    flex-direction:row;
    align-items: center;
}
.hire-list-card-badge-component-l{
    width: 40%;
}
.hire-list-card-badge-component-r{
    width: 60%;
    text-align: left;
}
.hire-list-card-badge-component img{
    width:1.5rem;
    height:1.5rem;
}
.hire-list-card-skillbar{
    width: 94%;
    margin-left: 3%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
}
.hire-card-skill-badge{
    color:#061d4a;
    font-weight: bold;
    font-size: 80%;
    text-align: center;
    margin-right: 0.5rem;
    background-color: #a1bcff;
    width:10rem;
    margin-left: 0.5rem;
    padding: 0.3rem;
    border: 0.1 rem solid #103961;
    border-radius: 5px;
    margin-top: 1rem;
    box-shadow: 1px 1px 1px 1px #a4a4a4;
}
.hire-list-card-bottom{
    margin-top: 1rem;
    font-size: 75%;
    margin-right: 2rem;
    color:#6f7275;
    text-align: right;
}
.hire-card-icon{
    height: 2.5rem;width: 2.5rem;
}

/* FORM PANEL */
.hire-form-container{
    width:96%;
    margin-left: 2%;
    border-radius: 15px;
    box-shadow: 2px 2px 2px 3px #a4a4a4;
    border: 0.1 rem solid #103961;
    margin-top: 12vh;
    font-size: 90%;
    background-color: #ffffff;
   /* height: 100vh; */
    overflow-y: scroll;
}
/* Form Elements */
.hire-form-list{
    display: flex;
    flex-direction: row;
}
.hire-form-group-large{
    width:94%;
    margin-left: 2%;
    padding: 1rem;
    display: flex;
    flex-direction:  row;
}
.hire-form-group-large .hire-form-group-left{
    width: 17%;
    text-align: left;
}
.hire-form-group-large .hire-form-group-right{
    width:83%;
}
.hire-form-group-small{
    width: 45%; 
    margin-left:2%; 
    padding: 1rem;
    display: flex;
    flex-direction: row;

}
.hire-form-group-small .hire-form-group-left{
    width:35%;
    text-align: left;
}
.hire-form-group-small .hire-form-group-right{
    width:65%
}
.hire-form-group-label{
    padding: 1rem;
    text-align: left;
}
.hire-form-group-input{
    width:100%;
    border:0.1rem solid #013cb1;
   /* box-shadow: 1px 2px 2px 1px #a4a4a4; */
    border-radius: 5px;
    box-shadow: 2px 1px 2px #777c86;
    padding: 1rem;

}


/*  HIRE TABLES */
.hire-table-container{
    width: 94%;
    margin-bottom:1rem;
    box-shadow: 2px 1px 2px 2px #777c86;
    border-radius: 5px; 
 /*   border:0.1rem solid #013cb1; */
    margin-left: 3%;
}
.hire-table-1{
    width:100%;
    padding: 0.5rem;
    text-align: center;
    overflow: scroll;
}
.hire-table-1 table{
    table-layout: fixed;
    overflow: scroll;
    width:100%;
}
.hire-table-1 thead{
    background-color: #021336;
    color:#ffffff;
}
.hire-table-1 th{
    padding: 0.5rem;

}
.hire-table-1 tr{
    box-shadow: 2px 1px 2px #777c86;
    border:0.1rem solid #6f7275;
}
.hire-table-1 td{

}



/* BUTTONS */
.hire-btn-default{
    margin-left: 1rem;
    padding:1rem;
    background-color: #013cb1;
    border-radius: 10px;
    font-weight: bold;
    border:0.1rem solid #ffffff;
    color:#ffffff;
    margin-top: 1rem;
    box-shadow: 1px 1px 1px 1px #a4a4a4;

}






/*  RESUME/ DETAILED CV CSS */

/* RESUME SECTION */
.hire-resume-section{
 width:94%;
 margin-left: 3%;
 margin-bottom: 1rem;
}
/* Hire Resume Card */
.hire-resume-card{
 width:100%;
 padding: 1%;
 box-shadow: 1px 1px 1px 1px #a4a4a4;
 border:0.2rem solid #143982;
 border-radius: 10px;
 text-align: left;
}
/* div break up */
.hire-resume-h-list{
    display: flex;
    flex-direction: row;
}
.hire-resume-dsize-2{
 width:48.5%;
 margin-left: 1%;
 margin-top: 1rem;
}
.hire-resume-dsize-3{
    width:32%;
    margin-left: 1%;
    margin-top: 1rem;
}
.hire-resume-dsize-4{
    width:23.75%;
    margin-left: 1%;
    margin-top: 1rem;
}
/* Resume Icon */
.hire-resume-ict-box{
    width:100%;
    height:3rem;
    display: flex;
    flex-direction: row;
}
.hire-resume-ict-icon{
    width:2rem;
    height:2rem;
}
.hire-resume-ict-text{
    margin-left: 1rem;
}

/* LOG PANEL */
.hire-log-panel{
    width:20%;
   /* height:40vh; */
    background-color: #ffffff;
    box-shadow: 2px 2px 3px 2px #a4a4a4;
    margin-top: 10vh;
    margin-left: 74%;
    margin-right: 1%;
    z-index: 1;
    position: fixed;
    font-size: 50%;
    text-align: left;
    padding: 1rem;
    display: none;
}
.hire-log-panel-list-item:hover{
  background-color: #013cb1;
  color: #ffffff;
  font-weight: bold;
  padding: 0.1rem;
}