


/* @media only screen and (min-width: 1200px) {} */
html{
    font-size: 100%;
}
body{
   /* font-size: 2rem;*/
}

/* PAGE */
.home-container{
    /* background-color: #013141; */
    background-color: #ffffff;
    width:100vw;
    height:100vh;
    
}

/* Header Section */
.header-section-top-pane{
width:100vw;

/*background-color: #bbdef3;*/
}

.inside-header{
  width:100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.inside-header-left{
  width: 60%;
  padding: 2rem;
  text-align: left;
}
.inside-header-left h1{
font-size: 3rem;
color:#125982;
text-align: left;
margin-bottom: 0;
}
.inside-header-left h3{
  margin-top: 1rem;
  font-size: 1.6rem;
  text-align: left;
}
.inside-header-left p{
  font-size: 1rem;
  color: #75797c;
  margin-top: 2rem;
}
.inside-header-right{
  width: 40%;
}
  /* HEADER SECTION */
 



  /* SIDEBAR */
  .inside-sidebar{
  /*  position: fixed; */
    position: absolute;
    display: block;
    background-color: #f1f0f0;
    color: #494646;
    margin-top: 2rem;
    height:100%;
    width: 100%;
  }
  .sidebar-content{
    /* position: fixed;*/
    position: absolute;
    padding: 1rem;
    z-index: 4;
  }

  .make-me-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0; 
  }
  .sidebar-content ul{
    list-style-type: none;
    text-align: left;
    padding: 2rem;
    font-weight: 600;
  }
  .sidebar-content a{
    color: #494646;
    text-decoration: none;
  }
  .sidebar-content li{
    padding: 1rem;

  }
  .sidebar-content li:hover{
    background-color: #2094ed;
    color: #efeeee;
    font-size: 1rem;
    margin-left: 1rem;
    border-radius: 5px;
    box-shadow: 0.1rem 0.2rem 0.1rem 0.1rem  #a6acaf;
  }

  #side-bar-icon{
    height:3rem;
    width:3rem;
    margin-top: 2rem;
    margin-left: 2rem;
  }

/* FEATURES SECTION */
.features-section{
    margin-top:3rem;
    width:100%;
    margin-bottom: 2rem;
}
.feature-card-list{
    width: 90%;
    margin-top: 1rem;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
}
.feature-card{
    width:30%;
    margin-left: 1.5%;
    margin-right: 1.5%;
   /* height: 60vh; */
   /* box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem #99c4f8;*/
    
}
.feature-card-top{
 
    display: flex;
    flex-direction: row;
}
#feature-icon{
    margin-left: 2rem;
    margin-top: 2rem;
    height: 4rem;
    width: 4rem;
   
}
.feature-card-heading{
    padding: 1rem;
   
}
.feature-card-content{
    padding: 1rem;
    text-align: left;
}
.feature-card-content h3{
    font-size: 1.4rem;
}
.feature-card-content p{
    font-size: 1rem;
}

/* BENEFIT SECTION */
.benefit-section{
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  width:96%;
  margin-left: 2vw;
  background-color: #e8e7e7;
  }
  
  .benefit-section-left{
  width:45%;
  margin-left: 3%;
  margin-right: 2%;
  
  }
  .benefit-section-right{
    width:45%;
    margin-left: 2%;
    margin-right: 3%;
  
  }
  .benefit-section-left h3{
    font-size: 2.4rem;
  }
  .benefit-section-left ul{
    margin-top: 2rem;
  }
  .benefit-section-left li{
    list-style-type: none;
    text-align: left;
  }
  #benefit-list-icon{
    margin-top: 1rem;
  height:3rem;
  width:3rem;
  }
  #benefit-list-heading{
    margin-top: 1rem;
  font-weight: bold;
  margin-left: 2rem;
  font-size: 1.4rem;
  }
  #benefit-list-content{
  margin-left: 5rem;
  }

  /* FAQ Section Start */

  .faq-section{
    margin-top: 3rem;
    width: 90%;
    margin-left: 5vw;
    margin-bottom: 3rem;
  }


  /* PROCESS */
.process-section{
  margin-top: 3rem;
  width:90%;
  margin-left: 5vw;
 background-color: #69c2f5;
 box-shadow: 0.1rem 0.2rem 0.1rem 0.1rem  #538698;
 border-radius: 15px;
 padding: 2rem;
}

.process-list{
display: flex;
flex-direction: row;
}
.process-list-card-a{
  width:20%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.process-list-card-b{
  width:13%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.process-list-item{
  width:6rem;
  height:6rem;
  padding: 1rem;
  background-color: #d8d8d8;
  box-shadow: 0.1rem 0.2rem 0.1rem 0.1rem  #adadad;
  border-radius: 50%;
  
}

#process-item{
  width: 3rem;
  height: 3rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}
#process-flow{
  margin-top: 1.5rem;
  width: 3rem;
  height: 3rem;
}

#process-list-heading{
margin-top: 2rem;
}


/* DOCUMENTS SECTION */
.documents-section{
  width: 90%;
  margin-left: 5%;
  margin-top: 3rem
}
.doc-section{
  padding: 2rem;
  border: 0.1rem solid #838181;
  box-shadow: 0.1rem 0.2rem 0.1rem 0.1rem  #adabab;
}
.doc-section-heading{

}


 /*  CONTACT FORM */
  .ip-contact-form{
  width:88%;
  margin-left: 6%;
 /* height: 56vh; */
  margin-top: 6vh;
/*  border : 0.2rem solid #a5b8c9; */
  background-color: #044680; 
 border: 0.1rem solid #5b666f;
  border-radius: 15px;
  box-shadow: .1rem .3rem .2rem #b1b9bf;
}
.ip-contact-form h5{

  color: #ffffff;
  text-align: center;
  
}
#ip-contact-form-ln{
  background-color: #ffffff;
  padding: 0.1rem;
  width:10%;
  margin-left: 45%;
}
.ip-form-input{
  margin-top: 1rem;
  padding: 0.5rem;
  width: 80%;
  margin-left: 10%;
  border: 0.1rem solid #70787e;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0.1rem 0.1rem 0.1rem #5b666f;
}
#ip-contact-submit{
  background-color: #b70101;
 /* margin-left: 33%; */
 /*width:80%;*/
 width:10rem;
 margin-left: 10%;
 margin-right: 10%;
  padding: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  border: 0.1rem solid #70787e;
  border-radius: 5px;
  box-shadow: 0rem 0.1rem #d6dbdf;
  color: #ffffff;
}


#ihl-getstarted{
  background-color: #01b771;
  margin-left: 5%;
  padding: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  border: 0.1rem solid #70787e;
  border-radius: 5px;
  box-shadow: 0rem 0.1rem #d6dbdf;
  color: #ffffff;
}


/*---------------------------------------------------------*/
  /*--------------------------FAQS-------------------------*/
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

:root {
  --accent-color: hsl(182, 79%, 34%);
}

h1 {
  margin: 50px 0 30px;
  text-align: center;
  font-size: 30px;
}


.faq-container {
  max-width: 1500px;
  margin: 0 auto;
  border-radius: 10px;
  background-color: hsla(0, 0%, 100%, 0.226);
  overflow: hidden;
  box-shadow: rgba(156, 212, 109, 0.459) 0px 4px 12px;
}

.faq {
  box-sizing: border-box;
  background: transparent;
  padding: 30px;
  position: relative;
  overflow: hidden;
}

.faq:not(:first-child) {
  border-top: 1px solid #e6e6e6;
}

.faq-title {
  margin: 0 35px 0 0;
  font-size: 20px;
}

.faq-text {
  margin: 30px 0 0;
  display: none;
  line-height: 1.5rem;
}

.faq.active {
  background-color: #f8f8f8;
  box-shadow: inset 4px 0px 0px 0px var(--accent-color);
}

.faq.active .faq-title {
  color: var(--accent-color);
}

.faq.active .faq-text {
  display: block;
}

.faq-toggle {
  background-color: transparent;
  border: 1px solid #e6e6e6;
  color: inherit;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  padding-top: 3px;
  position: absolute;
  top: 30px;
  right: 30px;
  height: 30px;
  width: 50px;
  transition: 0.3s ease;
}

.faq-toggle:focus {
  outline: none;
}

.faq.active .faq-toggle {
  transform: rotate(180deg);
 /* background-color: var(--accent-color); */
 /* border-color: var(--accent-color); */
  color: #ffffff75;
}


/* DIVERGION BLOCK */
.divergion-block{
  width:100%;
display: flex;
flex-direction: row;
}
.divergion-block-left{
  width:20%;
}
.divergion-block-right{
  width: 80%;
}


    /*
    =========================================================================================
                                      footer
    =========================================================================================
    */
    
    .footer-wrap {
      padding-top: 5rem;
      margin-top: 2rem;
      background-size: cover;
      background-color: #023149;
  }
  #footer-sm-icons{
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 1rem;

  }
  .footer-wrap h3 {
      color: #fff;
      font-size: 17px;
      text-transform: uppercase;
      margin-bottom: 25px;

  }
  
  .footer-wrap p {
      font-size: 13px;
      line-height: 24px;
      color: #b3b3b3;
      margin-top: 15px;
  }
  
  .footer-wrap p a {
      color: #fff;
      text-decoration: underline;
      font-style: italic;
  }
  
  .footer-wrap p a:hover {
      text-decoration: none;
      color: #ff7800;
  }
  
  .footer-links li a {
      font-size: 13px;
      line-height: 30px;
      color: #ccc;
      text-decoration: none;
  }
  
  
  .footer-links li:before {
    /*  content: "\f105"; */
      font-family: 'FontAwesome';
      padding-right: 10px;
      color: #ccc;

  }
  
  .footer-category li a {
      font-size: 13px;
      line-height: 30px;
      color: #ccc;
      text-decoration: none;
  }
  
  .footer-category li:before {
    /*  content: "\f105"; */
      font-family: 'FontAwesome';
      padding-right: 10px;
      color: #b3b3b3;
  }
  
  .address {
      
      color: #b3b3b3;
      font-size: 14px;
      position: relative;
      padding-left: 30px;
      line-height: 30px;
  }
  
  .address:before {
      content: "\f277";
      font-family: 'FontAwesome';
      position: absolute;
      top: 0;
      left: 0;
  }
  
  .info a {
   
      color: #b3b3b3;
      font-size: 14px;
      line-height: 30px;
      font-weight: normal;
  }
  
  .fa-phone:before {
      content: "\f095";
  }
  
  .info a {
  
      color: #b3b3b3;
      font-size: 14px;
      line-height: 30px;
      font-weight: normal;
  }
  
  .fa-fax:before {
      content: "\f1ac";
  }
  
  .copyright {
      border-top: 1px solid #111;
      font-size: 14px;
      color: #ccc;
      padding-top: 15px;
      text-align: center;
      padding-bottom: 15px;
      background: #222;
  }
  footer .second_class{
      border-bottom: 1px solid #444;
      padding-bottom: 25px;
  }
  footer .first_class {
      padding-bottom: 21px;
      border-bottom: 1px solid #444;
  }
  footer .first_class p, footer .first_class h3{
      margin: 0 0;
      
  }
  footer{
      background: #333;

  }
  
  footer .newsletter input[type="text"] {
      width: 100%;
      background: #fff;
      color: #333;
      border: 1px solid #222;
      padding: 14px 20px;
      border-radius: 50px;
      margin-top: 12px;
  }
  
  .newsletter .newsletter_submit_btn {
      background: #fff;
      position: absolute;
      right: 30px;
      border: 0;
      top: 26px;
      font-size: 17px;
      color: #333;
  }
  
  
  footer .second_class_bdr{
      padding-top: 25px;
      border-top:1px solid #222;
  }
  
  footer .btn-facebook a {
      padding: 6px 14px !important;
  }
  
  footer .btn-envelop a {
      color: #D6403A;
      font-size: 15px;
      padding: 12px 12px;
  }
  
  footer .round-btn a {
      padding: 6px 12px;
  }
  
  footer .round-btn {
      box-shadow: 2px 2px 5px 0px #222 !important;}
  
  footer .round-btn {
      margin: 15px 4px;}
    
  footer dl, ol, ul {
      padding-left: 5px;
  }
    footer li{
      list-style: none;
      text-align: left;
    }
  
  @media(max-width:768px){
      .footer-wrap h3 {
      margin-top: 27px;}
      
      footer .round-btn {
      margin: 15px 4px;}
  }
  @media(max-width:320px){
  .copyright {
      font-size: 13px;}
  } 


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {


    .feature-card-list{
        width: 90%;
        margin-top: 1rem;
        margin-left: 5%;
        display: flex;
        flex-direction: column;
    }
    /* Process */
    .process-section{
     /* display:none; */
    }
    /* Features List */
    .feature-card-list{
     /* width: 90%;
      margin-top: 1rem;
      margin-left: 5%;*/
      display: flex;
      flex-direction: column;
  }

}

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 762px) {

    .feature-card-list{
        width: 90%;
        margin-top: 1rem;
        margin-left: 5%;
        display: flex;
        flex-direction: row;
    }
    /* Process */
    .process-section{
      display:none; 
    }
    /* Features List */
    .feature-card-list{
    /*  width: 90%;
      margin-top: 1rem;
      margin-left: 5%; */
      display: flex;
      flex-direction: column;
  }
  
  }