
/**************************** TABLET VIEW CHANGES ********************************/
@media(max-width:768px){

    /* NAVBAR */
   /* navbar logo */
   #hire-navbar-logo-default{
   margin-top:0.1rem;
   height:7.2rem;
   width:7.2rem;
   }
   /*menu icon */
   #navbar-menu-icon{
   margin-left: 4rem;
   margin-top:1rem;
   height:5rem;
   width:5rem;
   }
   
   
   /* MAIN SECTION */
   .hire-main-section{
       display: flex;
       flex-direction: right;
       width: 100vw;
       font-size: 100%;
   }
   /* LEFT SIDEBAR */
   .hire-left-sidebar{
       width:60vw;
       box-shadow: 2px 1px 2px #777c86;
       height: 100vh;
       display: none;
       animation: fade-in 2s linear forwards;
       position: fixed;
       z-index: 2;
       background-color: #fafafa;
   }
   /* MAIN PANEL */
   .hire-main-panel{
       width:100vw;
       background-color: #fafafa;
       height: 2000vh;
       overflow-y: scroll;
       scroll-behavior: auto;
   
   }
   /* RIGHT SIDEBAR */
   .hire-right-sidebar{
       width:60vw;
       box-shadow: 2px 1px 2px #777c86;
       height: 100vh;
       display: none;
       animation: fade-in 2s linear forwards;
       position: fixed;
       z-index: 3;
       background-color: #fafafa;
   }
   
   /* FORM PANEL */
.hire-form-container{
    font-size: 50%;
}
/* Form Elements */
.hire-form-list{
    display: flex;
    flex-direction: row;
}
.hire-form-group-large{
    width:94%;
    margin-left: 1%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
}
.hire-form-group-large .hire-form-group-left{
    width:15%;
    text-align: left;
}
.hire-form-group-large .hire-form-group-right{
    width:83%;
}
.hire-form-group-small{
    width: 45%;
    margin-left: 0%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
}



/* LOG PANEL */
.hire-log-panel{
    width:30%;
   /* height:40vh; */
    background-color: #ffffff;
    box-shadow: 2px 2px 3px 2px #a4a4a4;
    margin-top: 10vh;
    margin-left: 69%;
    margin-right: 1%;
    z-index: 1;
    position: fixed;
    font-size: 50%;
    text-align: left;
    padding: 1rem;
}

   }    
   
   
   