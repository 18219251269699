
html{


}
body{
    max-width: 100vw;
}
/**************************** DEFAULT SCREEN SIZE 1200+ ********************************/

/* MAIN CONTAINER */
.newhome-main-container{
 font-size: 67%;
 width:100vw;
}

/* NAVBAR */
.newhome-navbar{
    background-color: #ffffff;
    box-shadow: 2px 1px 2px #777c86;
    height:9vh;
    display: flex;
    width: 100vw;
    flex-direction: row;
    z-index: 1;
    max-width: 100vw;
}
.newhome-navbar-left{
    margin-top: 1vh;
    width:30%;
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    align-items: left;
}
.newhome-navbar-right{
    margin-top: 1vh;
    width:70%;
    display: flex;
    flex-direction: row;
}
.newhome-navbar-items-list{
    width:100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: right;
    margin-right: 1rem;
}
.newhome-navbar-link{
   margin-left: 2rem;
   padding: 1rem;
}
.newhome-navbar-link:hover{
    color: #0b2e75;
    font-weight: bold;
}
 .newhome-navbar-link a{
     color:#000000;
     text-decoration: none;
}

#newhome-logo-default{
    height:4.5rem;
    width:4.5rem;
}
 /*menu icon */
 #navbar-menu-icon{
    margin-top: 0;
    margin-left: 4rem;
    height:4rem;
    width:4rem;
    display: none;
    }

/* Vertical Navbar */
.newhome-vertical-navbar{
  display: none;  
  background-color: #ffffff;
  box-shadow: 1px 2px 2px 1px #797779;
  width:50vw;
  margin-left: 45vw;
  position: fixed;
  z-index: 2;
  border-radius: 5px;
}
.newhome-vertical-navbar-items-list{
    
}
.newhome-vertical-navbar-link{
 padding: 1rem;
}
.newhome-vertical-navbar-link:hover{
   color: #0b2e75;
   font-weight: bold;
}
.newhome-vertical-navbar-link a{
    color:#000000;
    text-decoration: none;
   }

/* DISPLAY SECTION */
.newhome-display-section{
    position: fixed;  
    height: 100vh;
    width:100vw;
    overflow-y: scroll;
    background-image: url("./Resources/home2-bg.png");
    background-repeat: repeat; 
    background-position: left; 
    max-width: 100vw;
    /*width:100%;*/
}
.newhome-display-centre{
    margin-top: 0;
    background-color: transparent;
    color: #ffffff;
}
.newhome-display-centre-para{
    font-size: 3.5rem;
    text-align: center;
    width:60%;
    margin-left: 20%;
}
.newhome-display-centre-para p{
    margin-top: 15%;
}
.newhome-display-centre-btn{
    font-size: 120%;
    text-align: center;
    width:50%;
    margin-left: 25%;
}
#newhome-display-getstarted-btn{
    background-color: transparent;
    color: #ffffff;
    border: 0.1rem solid;
    box-shadow: 1px 1px 1px 1px #dad5d5;
    border-radius: 5px;
    padding: 1rem;
    font-size: 1.6rem;

}
/* Primary List */
.newhome-display-primarylist{
    width: 90%;
    margin-left: 5%;
    margin-top: 10rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 5vh;
}
.newhome-display-primarylist-item{
    background-color: transparent;
    color: #ffffff;
    border: 0.2rem solid;
    box-shadow: 1px 1px 1px 1px #82677e;
    border-radius: 5px;
    height:14rem;
    width:14rem;
    margin-left: 3rem;
    margin-right: 3rem;

}
/* Primary list card items */
.newhome-display-primarylist-item-img{
   height:7rem;
   width:7rem;
   margin-top: 1rem;
}
.newhome-display-primarylist-item p{
    font-size: 1.6rem;
}

/* INTRODUCTION SECTION */
.newhome-introduction-section{
    width:98vw;
    display: flex;
    flex-direction: row;
}
.newhome-introduction-section-left{
    width:50%;
  /*  height:60vh; */
    color: #ffffff;
    text-align: left;
    padding: 2rem;
}
.newhome-introduction-section-right{
    width: 50%;
  /*  height: 60vh; */
    background-color: #ffffff;
    text-align: left;
    padding: 2rem;
}
.newhome-introduction-upcoming{
    color: #000000;
    background-color: #ffffff;
    width: 100%;
    padding: 1rem;
}
.newhome-introduction-section-list{
  width:100%;
  display: flex;
  flex-direction: row;
}
.newhome-introduction-section-list-left{
  width:16%
}
.newhome-introduction-section-list-right{
  width: 84%;
}
#newhome-introduction-section-list-img{
    width:4rem;
    height:4rem;
    margin-top: 2rem;
    box-shadow: 1px 3px 2px 3px #797779;
    border-radius: 3px;
}
.newhome-introduction-section-list-right p{
    color:#6f7072
  }
  /* Upcoming List Item */
  .newhome-introduction-upcominglist{
    width: 90%;
    margin-left: 5%;
    margin-top: 10rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 5vh;
}
  .newhome-introduction-upcominglist-item{
    background-color: transparent;
    color: #000000;
    border: 0.2rem solid;
    box-shadow: 1px 2px 1px 2px #804577;
    border-radius: 5px;
    width:14rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;

}
/* Upcoming list card items */
.newhome-introduction-upcominglist-item-img{
   height:7rem;
   width:7rem;
   margin-top: 1rem;
   margin-left: 3rem;
}
.newhome-introduction-upcominglist-item p{
    font-size: 1.6rem;
}


/* OUR PORTALS */
.newhome-portals-section{
 background-color: #ffffff;
 width:90vw;
 margin-top: 1rem;
 padding: 3rem;
 max-width: 100vw;
 margin-left: 2vw;
}
/* Portal Card */
.newhome-portal-section-card{
  width:96%;
  height:80vh;
  margin-left: 2%;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
}
.newhome-portal-section-card-left{
    width:45%;
    margin-top: 5vh;
}
.newhome-portal-section-card-right{
    width:45%;
    margin-top: 5vh;
}
/* Card Switching for alternatives */
.newhome-portal-section-card:nth-child(2n) {
    .newhome-portal-section-card-left {
      width: 48%;
      margin-top: 5vh;
      order: 2;
    }
  
    .newhome-portal-section-card-right {
      width: 48%;
      margin-top: 5vh;
      order: 1;
    }
  }
.newhome-portal-section-card-left-imgsection{
    width:90%;
    height:48vh;
    margin-left: 5%;
    border-radius: 5px;
}
#newhome-portal-section-card-left-img{
    height:90%;
    margin-top: 1%;
    width:90%;
    margin-left: 1%;
    border-radius: 5px;
    box-shadow: 2px 3px 2px 2px #c0c0c0;
}
.newhome-portal-section-card-right-content{
    width:100%;
    text-align: left;
    padding: 2rem;
}
.newhome-portal-section-card-right-content p{
   text-align: left;
}
.newhome-portal-section-card-right-content h1{
    margin-top: 0;
    margin-left: 0;
    text-align: left;
}
#newhome-portal-section-getstarted-btn{
    background-color: #0b2e75;
    color:#ffffff;
    border:0.1rem solid #dad5d5;
    box-shadow:1px 1px 2px 1px #d0d0d0;
    padding: 1rem;
    border-radius: 5px;
    width: 14rem;
    font-weight: bold;
    font-size: 1.4rem;
}

/* FOLLOWUP BAR */
.newhome-followupbar{
    width: 87.5%;
    margin-left: 2%;
    background: linear-gradient(to right, rgb(16, 19, 22), rgb(2, 19, 43));
    color: #ffffff;
    text-align: left;
    padding: 5rem;
    display: flex;
    flex-direction: row;
  }
.newhome-followupbar-left{
 width:70%;
}
.newhome-followupbar-right{
 width:30%;
}
  .newhome-followupbar-left h2{
    font-size: 3rem;
    
}
#newhome-followupbar-icon{
    height:18rem;
    width:18rem;
}

/* FOOTER */
.newhome-footer{
    width: 100vw;
    height:40vh;
   /* background-color: #000000; */
   background-image: url("./Resources/home2-bg.png");
   background-repeat: repeat;
   background-position: left;
    display: flex;
    flex-direction: row;
    color: #ffffff;
}
.newhome-footer-left{
 width:65%;
 display: flex;
 flex-direction: row;
}
.newhome-footer-list{
    width:30%;
    margin-left: 1.5%;
    padding: 1rem;
}
.newhome-footer-list h3{
    text-align: left;
}
.newhome-footer-list ul{
    text-align: left;
    list-style: none;
}
.newhome-footer-list a{
    text-align: left;
    text-decoration: none;
    color:#ffffff;
}
.newhome-footer-right{
    width:35%;
    text-align: left;
}
#newhome-footer-email-input{
    margin-top: 4rem;
    background-color: #000000;
    border:0.1rem solid #c8a718;
    box-shadow: 1px 1px 1px 1px #afa75b;
    padding: 1rem;
    width:70%;
    color: #ffffff;
}
#newhome-footer-email-check{
    margin-top: 1rem;
    border: 0.1rem solid #c8a718;
}
.newhome-footer-right span{
    margin-top: 1rem;
    font-size: 70%;
}
.newhome-footer-right a{
    color: #ffffff;
}
#newhome-footer-sendnewsletter-btn{
    box-shadow: 1px 1px 1px 1px #afa75b;
    border:0.1rem solid #c8a718;
    background-color: #c8a718;
    color: #ffffff;
    padding: 1rem;
    width: 45%;
    margin-top: 1rem;
    font-weight: bold;
    font-size: 1.2rem;
    border-radius: 5px;
    
}

/* Copyright Section */
.newhome-copyright-section{
 background-color: #000000;
 width: 100vw;
 height: 5vh;
 color: #ffffff;
 font-size: 80%;
 margin-bottom: 3rem;
}


/**************************** SCREEN SIZE 1200px ********************************/
@media(max-width:1200px){

/* NAVBAR */
.newhome-navbar{
height: 8vh;
}
.newhome-navbar-left{
  margin-top: 1rem;
  font-size: 75%;
}
.newhome-navbar-right{
  margin-top: 1rem;
  font-size: 75%;
}

/* DISPLAY SECTION */
/* primary list */
.newhome-display-centre-para{
    font-size: 3rem;
    text-align: center;
    width:80%;
    margin-left: 10%;
}
.newhome-display-centre-para p{
    margin-top: 15%;
}
.newhome-display-primarylist-item{
    height:10rem;
    width:12rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;

}
/* Primary list card items */
.newhome-display-primarylist-item-img{
    height:5rem;
    width:5rem;
    margin-top: 1rem;
 }
 .newhome-display-primarylist-item p{
     font-size: 1.2rem;
 }
 /* Upcoming list card items */
.newhome-introduction-upcominglist-item-img{
    height:6rem;
    width:6rem;
    margin-top: 1rem;
    margin-left: 2rem;
 }
 .newhome-introduction-upcominglist-item p{
     font-size: 1.6rem;
 }

 /* OUR PORTALS */
.newhome-portals-section{
    margin-left: 1.5vw;
   }

   
/* FOLLOWUP BAR */
.newhome-followupbar{
    width: 85%;
    margin-left: 1%;
  }
}

/**************************** SCREEN SIZE 1020px ********************************/
@media(max-width:1020px){

/* NAVBAR */
.newhome-navbar-left{
    font-size: 70%;
  }
  .newhome-navbar-right{
    font-size: 70%;
  }

  /* DISPLAY SECTION */
  /* primary list */
.newhome-display-primarylist-item{
    height:9rem;
    width:10rem;
    margin-left: 2.2rem;
    margin-right: 2.2rem;

}
/* Primary list card items */
.newhome-display-primarylist-item-img{
    height:5rem;
    width:5rem;
    margin-top: 0.7rem;
 }
 .newhome-display-primarylist-item p{
     font-size: 1rem;
 }
 /* Upcoming list card items */
 .newhome-introduction-upcominglist-item-img{
    height:5rem;
    width:5rem;
    margin-top: 1rem;
    margin-left: 2rem;
 }
 .newhome-introduction-upcominglist-item p{
     font-size: 1.2rem;
 }

}

/**************************** SCREEN SIZE 880px ********************************/
@media(max-width:880px){

    
/* NAVBAR */
    .newhome-navbar{
    height: 7vh;
    }
    .newhome-navbar-left{
      margin-top: 1rem;
      font-size: 65%;
      width:10%
    }
    .newhome-navbar-right{
      margin-top: 1rem;
      font-size: 65%;
      width: 90%;
    }
    #newhome-logo-default{
        height:3.8rem;
        width:3.8rem;
    }
    #newhome-logo-heading{
        display: none;
    }

      /* DISPLAY SECTION */
      /* primary list */
.newhome-display-centre-para p{
    margin-top: 25%;
}
.newhome-display-primarylist-item{
    height:8rem;
    width:9rem;
    margin-left: 2rem;
    margin-right: 2rem;

}
/* Primary list card items */
.newhome-display-primarylist-item-img{
    height:4.4rem;
    width:4.4rem;
    margin-top: 0.7rem;
 }
 .newhome-display-primarylist-item p{
     font-size: 1rem;
 }
 /* INTRODUCTION SECTION */
.newhome-introduction-section{
    width:100vw;
    display: flex;
    flex-direction: column;
}
.newhome-introduction-section-left{
    width:80%;
    margin-left: 10%;
}
.newhome-introduction-section-right{
    width: 80%;
    margin-left: 10%;
}
/* Upcoming list */
.newhome-introduction-upcominglist{
    width: 100%;
    margin-left: 0;
}
  /* Upcoming list card items */
  .newhome-introduction-upcominglist-item-img{
    height:7rem;
    width:7rem;
    margin-top: 1rem;
    margin-left: 3rem;
 }
 .newhome-introduction-upcominglist-item p{
     font-size: 1.6rem;
 }

 /* PORTAL */
 /* OUR PORTALS */
.newhome-portals-section{
    margin-left: 0.5vw;
   }
 /* Portal Card */
.newhome-portal-section-card{
    width:90vw;
    height: 120vh;
    margin-left: 2vw;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
  }
  .newhome-portal-section-card-left{
      width:90%;
      margin-top: 5vh;
  }
  .newhome-portal-section-card-right{
      width:90%;
      margin-top: 5vh;
  }
  /* Card Switching for alternatives */
.newhome-portal-section-card:nth-child(2n) {
    .newhome-portal-section-card-left {
      order: 1;
      width:90%;
      margin-top: 5vh;
    }
  
    .newhome-portal-section-card-right {
      order: 2;
      width:90%;
      margin-top: 5vh;
    }
  }  

  /* FOLLOWUP BAR */
.newhome-followupbar{
    width: 84%;
    margin-left: 0;
  }
}

/**************************** SCREEN SIZE 720px********************************/
@media(max-width:720px){
    
/* NAVBAR */
  .newhome-navbar-left{
    margin-top: 1rem;
    width:80%
   }
  .newhome-navbar-right{
    margin-top: 1rem;
    width: 20%;
   }
    .newhome-navbar-items-list{
        display: none;
      }
    #newhome-logo-default{
        height:4rem;
        width:4rem;
    }
    #navbar-menu-icon{
       display: block;
    }

      /* DISPLAY SECTION */
      /* primary list */
.newhome-display-centre-para p{
    margin-top: 33%;
}
.newhome-display-primarylist-item{
    height:7.2rem;
    width:8rem;
    margin-left: 2rem;
    margin-right: 2rem;

}
/* Primary list card items */
.newhome-display-primarylist-item-img{
    height:3.2rem;
    width:3.2rem;
    margin-top: 0.5rem;
 }
 .newhome-display-primarylist-item p{
     font-size: 1rem;
 }
  /* OUR PORTALS */
.newhome-portals-section{
    margin-left: 0vw;
   }
    
}

/**************************** SCREEN SIZE 600px********************************/
@media(max-width:600px){
    
   /* NAVBAR */
   .newhome-navbar-left{
    margin-top: 1rem;
    width:80%
   }
   .newhome-navbar-right{
    margin-top: 1rem;
    width: 20%;
   }
    .newhome-navbar-items-list{
        display: none;
      }
      #newhome-logo-default{
        height:5rem;
        width:5rem;
     }
      #navbar-menu-icon{
        display: block;
      }

        /* DISPLAY SECTION */
.newhome-display-centre-para p{
    margin-top: 40%;
}
.newhome-display-primarylist-item{
    height:5rem;
    width:6rem;
    margin-left: 2rem;
    margin-right: 2rem;

}
/* Primary list card items */
.newhome-display-primarylist-item-img{
    height:3.4rem;
    width:3.4rem;
    margin-top: 0.5rem;
 }
 .newhome-display-primarylist-item p{
     font-size: 1rem;
 }


}