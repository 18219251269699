html{
font-size: 100%;
}


/* DASHBOARD CARDS */
.btools-card-header{
    border:0.2rem solid #2480dc;
    border-radius:5px;
   
}
.btools-card-icon{
    font-size:8rem;
    color:#2480dc;
}

#create-form{
  display:none;
  text-align: left;
  text-align: left;
    
}
#create-form1{
    display:none;
    text-align: left;
      
  }


/**************************** MEDIUM PC VIEW CHANGES ********************************/
@media(max-width:1200px){

 .card-body p{
    font-size: 1rem !important;
  }


}


/**************************** SMALL PC VIEW CHANGES ********************************/
@media(max-width:980px){

  
  .card-body p{
    font-size: 1rem !important;
  }

}



/**************************** TABLET VIEW CHANGES ********************************/
@media(max-width:768px){

  
  .card-body p{
    font-size: 1rem !important;
  }

}


/**************************** MOBILE VIEW CHANGES ********************************/
@media(max-width:600px){

  
  .card-body p{
    font-size: 0.8rem !important;
  }

}