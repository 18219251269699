

.custom-task-cards-group{
    display:block;
    max-width:100%;
    text-align:center;
    margin:0 auto;
}

.custom-task-card{
    display:inline-block;
    width:45%;
    margin:0 25px 25px;
}