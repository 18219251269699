
/**************************** MOBILE VIEW CHANGES ********************************/
@media(max-width:600px){

    /* NAVBAR */
    /* navbar logo */
    #hire-navbar-logo-default{
    margin-top:0.1rem;
    height:8rem;
    width:8rem;
    }
    /*menu icon */
    #navbar-menu-icon{
    margin-left: 4rem;
    margin-top:1rem;
    height:6rem;
    width:6rem;
    }

    
/* MAIN SECTION */
.hire-main-section{
    display: flex;
    flex-direction: right;
    width: 100vw;
    font-size: 125%;
}
/* LEFT SIDEBAR */
.hire-left-sidebar{
    width:80vw;
    box-shadow: 2px 1px 2px #777c86;
    height: 100vh;
    display: none;
   animation: fade-in 2s linear forwards; 
    position: fixed; 
    z-index: 2;
    background-color: #fafafa;

}
/* MAIN PANEL */
.hire-main-panel{
    width:100vw;
    background-color: #fafafa;
    height: 2000vh;
    overflow-y: scroll;
    scroll-behavior: auto;
}
/* RIGHT SIDEBAR */
.hire-right-sidebar{
    width:80vw;
    box-shadow: 2px 1px 2px #777c86;
    height: 100vh;
    display: none;
    animation: fade-in 2s linear forwards;
    position: fixed;
    z-index: 3;
    background-color: #fafafa;
    background-color: #fafafa;
}


/* MAIN PANEL */
/* Card List */
/* Card */
/* Hire List Card Top */
.hire-list-card-top{
    margin-left: 2rem;
    display:flex;
    flex-direction: column;
}
.hire-list-card-top-l{
    margin-left: 1rem;
    width: 100%;

}
.hire-list-card-top-r{
    margin-left:1rem;
    margin-top: -6rem;
    font-size: 90%;
    text-align: left;
    width: 100%;
}
/* Hire List Card Badge */
.hire-list-card-badge{
    color:#777c86;
    font-size: 60%;
    text-align: center;
    margin-right: 1rem;
    background-color: #e1e1e2;
    width:70%;
    margin-left: 15%;
    padding: 0.3rem;
    border: 0.1 rem solid #949698;
    border-radius: 5px;
    margin-top: 1rem;
    box-shadow: 1px 1px 1px 1px #a4a4a4;
}
/* Hire List Card Skill Badge */
.hire-card-skill-badge{
    color:#061d4a;
    font-weight: bold;
    font-size: 60%;
    text-align: center;
    margin-right: 0.5rem;
    background-color: #a1bcff;
    width:10rem;
    margin-left: 0.5rem;
    padding: 0.3rem;
    border: 0.1 rem solid #103961;
    border-radius: 5px;
    margin-top: 1rem;
    box-shadow: 1px 1px 1px 1px #a4a4a4;
}


  /* FORM PANEL */
  .hire-form-container{
    font-size: 70%;
}
/* Form Elements */
.hire-form-list{
    display: flex;
    flex-direction: column;
}
.hire-form-group-large{
    width:94%;
    margin-left: 2%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}
.hire-form-group-large .hire-form-group-left{
    width:94%;
    text-align: left;
}
.hire-form-group-large .hire-form-group-right{
    width:94%;
}
.hire-form-group-small{
    width: 94%;
    margin-left: 2%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}
.hire-form-group-small .hire-form-group-left{
    width:94%;
    text-align: left;
}
.hire-form-group-small .hire-form-group-right{
    width:94%;
}
.hire-form-group-label{
    padding: 1rem;
    text-align: left;
}
.hire-form-group-input{
    width:94%;
    border:0.1rem solid #013cb1;
   /* box-shadow: 1px 2px 2px 1px #a4a4a4; */
    border-radius: 5px;
    padding: 1rem;

}


/*  RESUME/ DETAILED CV CSS */

/* RESUME SECTION */
.hire-resume-section{
    width:94%;
    margin-left: 3%;
    margin-bottom: 1rem;
    font-size: 50%;
   }



    }    

