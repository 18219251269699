
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@200;300;400;500;600&family=Nunito:ital@1&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&family=Roboto:ital@1&family=Secular+One&display=swap');

body{
    font-size: 1rem;
}

/* NAVBAR */
.home2-navbar{
  width:100vw;
  padding: 1rem;
  background-color: #ffffff;
}
.home2-navbar ul{
  list-style-type: none;
  align-items: row;
}
.home2-navbar li{
  padding:1rem;
}
#home2-navbar-logo{
  margin-left: 0.5rem;
  width:50px;
  height:50px;
}
#home2-navbar-login{
  font-family: 'Open Sans', sans-serif;
  background-image: linear-gradient(to right, #4784af, #3533cd);
  border:#ffffff;
  color:#ffffff;
  border-radius:12px;
  width:7rem;
  padding: 0.5rem;
  font-weight: bold;
}

#home2-navbar-register{
  font-family: 'Open Sans', sans-serif;
  background-color: #1beb17;
  border:#ffffff;
  color:#ffffff;
  border-radius:12px;
  width:7rem;
  padding: 0.5rem;
  font-weight: bold;
}
#home2-navbar-changed{
  display:none;
}



/* HEADER SECTION */
.home2-header-section{
    background-image: url("/public/home1/page1.png");
    background-position: center;
    height:100vh;
    width:100vw;
}

/* HEADER MAIN */
.home2-header-main{
    display: flex;
    flex-direction: row;
    width:100%;
    padding: 8%;
}
.home2-header-main-left{
    width:54%;
    text-align: left;
}
.home2-header-main-right{
    width:46%;
    text-align: left;
}
.home2-header-main-left h1{
    font-family: 'League Spartan', sans-serif;
    font-weight: bold;
    font-size: 4.3rem;
    text-align: left;
}
.home2-header-main-left p{
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4rem;
    text-align: left;
}
#home2-header-main-btn{
    margin-top: 1rem;
    margin-left: 1rem;
    font-family: 'Open Sans', sans-serif;
    background-image: linear-gradient(to right, #17888d, #004aad);
    border:#ffffff;
    color:#ffffff;
    border-radius:12px;
    width:13rem;
    padding: 0.8rem;
    font-weight: bold;
}
#home2-wtp-icon{
    height:4rem;
    width:4rem;
    margin-left:28rem;
    box-shadow:1px 2px 1px 1px #d6d6d6;
    border-radius: 12px;
}




/* FEATURES SECTION */
.home2-features-section{
   /* height: 100vh; */
    width: 100vw;
   }
   .home2-features-section h1{
     text-align: center;
     font-weight: bold;
     font-family: 'League Spartan', sans-serif;
     margin-top:2rem;
   }
   .home2-features-section p{
       text-align: center;
       font-size: 1rem;
       font-family: 'Open Sans', sans-serif;
       color:#545454;
     }
   .home2-features-row{
     display: flex;
     flex-direction: row;
     justify-content: space-evenly;
   }
   .home2-features-card{
     padding:3rem;
     width:25%;
     height:25%;
     border-bottom: 0.2rem solid ;
     border-image: linear-gradient(to right, #8d1777, #ad0082) 1;
   }
   .home2-features-card-top{
   display: flex;
   flex-direction: row;
   width: 100%;
   margin-bottom: 1rem;
   }
   .home2-features-card-top-left{
     width:30%;
     background-image: linear-gradient(to right, #8d1777, #ad0082);
     border-radius: 12px;
     height:40%;
   }
   .home2-features-card-top-right{
     width:70%;
   }
   .home2-features-card-top-right h3{
       font-family: 'League Spartan', sans-serif;
       font-size: 2.4rem;
       margin-top: 1rem;
       color:#8d1777;
       font-weight: bold;
   }
   .home2-features-card-content{
       color:#545454;
       font-size: 1.2rem;
       font-family: 'Open Sans', sans-serif;
   }
   #home-feature-card-icon{
    height:4rem;
    width:3.5rem;
    padding:0.5rem;
   }



/* MODULES */
/* SERVICES SECTION */
.home2-modules-section h1{
  font-family: 'League Spartan', sans-serif;
  font-size: 2.8rem;
  font-weight: bold;
  text-align: center;
}
.home2-modules-section p{
  font-family: 'Open Sans', sans-serif;
  text-align: center;   
  font-size: 1.3rem;
  color:#545454;
}
.home2-services-section{
  background-image: url("/public/home1/page3.png");
  background-position: center;
  height:100vh;
  width:100vw;
}
.home2-services-section-container{
display: flex;
flex-direction: row;
}
.home2-services-section-left{
  width:60%;
  padding: 5%;
  text-align: left;
}
.home2-services-section-right{
  width:40%;
}
.home2-services-section-left h2{
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color:#FFBD59;
}
.home2-services-section-left p{
  margin-top: 2rem;
  font-family: 'Open Sans', sans-serif;
  text-align: left;   
  color:#545454;
}
.home2-services-features-list{
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: left;
  margin-top: 2rem;
}
.home2-services-fcard{
  display: flex;
  flex-direction: row;
  padding: 1rem;
}
.home2-services-fcard-left{
  
}
.home2-services-fcard-right{
  margin-left: 1rem;
}
#home2-services-fcard-icon{
height:3rem;
width:3rem;
}
#home2-modules-section-btn{
  margin-top: 1rem;
  margin-left: 1rem;
  font-family: 'Open Sans', sans-serif;
  background-image: linear-gradient(to right, #17888d, #004aad);
  border:#ffffff;
  color:#ffffff;
  border-radius:12px;
  width:17rem;
  padding: 0.8rem;
  font-weight: bold;
}



/* TOOLS SECTION */
.home2-tools-section{
  background-image: url("/public/home1/page4.png");
  background-position: center;
/*  height:100vh; */
  width:100%;
}
.home2-tools-section h2{
  font-family: 'Open Sans', sans-serif;
  color:#024BA9;
  font-weight: bold;
  font-size: 2.4rem;
}
.home2-tools-section p{
  font-family: 'Open Sans', sans-serif;
  color:#504545;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}
.home2-tools-section-container{
display: flex;
flex-direction: row;
}
.home2-tools-section-right{
  width:60%;
  padding: 5%;
  text-align: left;
}
.home2-tools-section-left{
  width:40%;
}
.home2-tools-section-right h3{
  font-family: 'League Spartan', sans-serif;
  font-weight: bold;
  color:#000000;
  font-size: 2.2rem;
}
.home2-tools-section-right p{
  margin-top: 2rem;
  font-family: 'Open Sans', sans-serif;
  text-align: left;   
  color:#545454;
}
.home2-tools-fcard{
width:90%;
margin-left: 5%;
display: flex;
flex-direction: row;
}
.home2-tools-fcard-left{
width:20%;
height:100%;
}
.home2-tools-fcard-right{
  width:80%;
  padding: 1rem;
 font-family: 'Nunito', sans-serif;
  color:#504545;
 
}
.home2-tools-fcard-right h5{
  padding: 1rem;
 font-family: 'Nunito', sans-serif;
  color:#504545;
  font-weight: bold;
 
}
.home2-tools-fcard-right p{
  margin-top: 0;
  padding: 1rem;
 font-family: 'Nunito', sans-serif;
  color:#504545;
  font-weight: normal;
 
}
#home2-tools-fcard-icon {
  height:5rem;
  width:5rem;
  margin-top:2.5rem;
   box-shadow:#b0b0b0 0.2rem 0.2rem 0.3rem 0.2rem;
   border-radius:15px;
}



/* NETWORK SECTION */
.home2-network-section{
  background-image: url("/public/home1/page5.png");
  background-position: center;
  height:100vh; 
  width:100%;
}
.home2-network-section h2{
  font-family: 'Open Sans', sans-serif;
  color:#1beb17;
  font-weight: bold;
  font-size: 2.4rem;
}
.home2-network-section p{
  font-family: 'Open Sans', sans-serif;
  color:#626161;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}
.home2-network-container{
  display: flex;
  flex-direction: row;
  padding: 5rem;
 /* justify-content: space-between;*/
}
.home2-network-fcard{
width:40%;
margin-right: 3rem;
}
.home2-network-fcard-top{
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  padding: 1rem;
}
.home2-network-fcard-top-l{
  width:30%;
}
.home2-network-fcard-top-r{
  width:70%;
}
.home2-network-fcard-top-r h3{
  font-family: 'League Spartan', sans-serif;
  font-weight: bold;
  color:#000000;
  font-size: 2.2rem;
  text-align: left;
}
.home2-network-fcard-content{
  background-color: #f5f5f5;
  padding: 2rem;
  height:40vh;
  border-radius:15px;
  
}
.home2-network-fcard-content p{
  color:#504545;
  text-align: left;
}




/* CONTEST SECTION */
.home2-contest-section{
  background-image: url("/public/home1/page9.png");
  background-position: center;
  height:100vh; 
  width:100%;
}
.home2-contest-section h2{
  font-family: 'Open Sans', sans-serif;
  color:#8d1777;
  font-weight: bold;
  font-size: 2.4rem;
  color:#ffffff;
}
.home2-contest-section p{
  font-family: 'Open Sans', sans-serif;
  color:#626161;
  font-size: 1.2rem;
  text-align: center;
  color:#ffffff;
}



/* HIRING SECTION */
.home2-hiring-section{
  background-image: url("/public/home1/page6.png");
  background-position: center;
 /* height:100vh; */
  width:100%;
}
.home2-hiring-section h2{
  font-family: 'Open Sans', sans-serif;
  color:#8d1777;
  font-weight: bold;
  font-size: 2.4rem;
}
.home2-hiring-section p{
  font-family: 'Open Sans', sans-serif;
  color:#626161;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}
.home2-hiring-container{
  display: flex;
  flex-direction: row;
/*  padding: 1rem; */
 /* justify-content: space-between;*/
}
.home2-hiring-section-right{
    width:60%;
    padding: 5%;
    text-align: left;
}
.home2-hiring-section-left{
    width:40%;
}
.home2-hiring-card{
  display: flex;
  flex-direction: row;
  width:90%;
  padding: 1rem;
  background-image: linear-gradient(to right, #8d1717, #ad0000);
  margin-top: 0.5rem;
  border-radius: 12px;
}
.home2-hiring-card-left{
  width:20%;
  padding: 0.5rem;
}
.home2-hiring-card-right{
  padding: 0.5rem;
  width:80%;

}
.home2-hiring-card-right h4{
  font-weight: bold;
  font-family: 'League Spartan', sans-serif;
  color:#ffffff;
}
.home2-hiring-card-right p{
  text-align: left;
  font-weight: normal;
  font-family: 'Open Sans', sans-serif;
  color:#ffffff;
}
#home2-hiring-side-img{
  height:60vh;
  margin-top:10%;
  width:96%;
  margin-left:4%;
}
#home2-hiring-card-icon{
  height:5rem;
  width:5rem;
  margin-right:2rem;
}


  /* Large devices (laptops/desktops, 1340px and up) */
  @media only screen and (min-width: 1340px) {


    /* HEADER MAIN */
    .home2-header-main{
        padding: 7%;
    }
    .home2-header-main-left{
        width:60%;
    }
    .home2-header-main-right{
        width:40%;
    }
    .home2-header-main-left h1{
        font-size: 4.1rem;
    }
    .home2-header-main-left p{
        font-size: 1.3rem;
    }
    
  }

  
  /* Large devices (laptops/desktops, 1220px and up) */
  @media (min-width:1220px) and (max-width:1340px){




    /* HEADER SECTION */
    .home2-header-section{
      background-image: url("/public/home1/page1.png");
      background-position: center;
      height:80vh;
      width:100vw;
    }
    /* HEADER MAIN */
    .home2-header-main{
        padding: 5%;
    }
    .home2-header-main-left{
        width:66%;
    }
    .home2-header-main-right{
        width:34%;
    }
    .home2-header-main-left h1{
        font-size: 3.8rem;
    }
    .home2-header-main-left p{
        font-size: 1.2rem;
    }
    #home2-wtp-icon{
        height: 3.4rem;
        width:3.4rem;
        margin-left:21rem;
    }


    
/* FEATURES SECTION */
   .home2-features-row{
     display: flex;
     flex-direction: row;
     justify-content: space-evenly;
   }
   .home2-features-card{
     padding:1.8rem;
     width:28%;
     height:25%;
   }
   .home2-features-card-top-left{
     width:30%;
     height:40%;
   }
   .home2-features-card-top-right{
     width:70%;
   }
   .home2-features-card-top-right h3{
       font-size: 2.2rem;
   }
   .home2-features-card-content p{
       font-size: 1.2rem;
   }
   #home-feature-card-icon{
    height:3.6rem;
    width:3rem;
    padding:0.5rem;
   }

    
  }

   /* Large devices (laptops/desktops, 1100px and up) */
   @media (min-width:1100px) and (max-width:1220px){
    
        /* HEADER SECTION */
        .home2-header-section{
            background-image: url("/public/home1/page1.png");
            background-position: center;
            height:82vh;
            width:100vw;
          }
        /* HEADER MAIN */
        .home2-header-main{
            padding: 5%;
        }
        .home2-header-main-left{
            width:70%;
        }
        .home2-header-main-right{
            width:30%;
        }
        .home2-header-main-left h1{
            font-size: 3.8rem;
        }
        .home2-header-main-left p{
            font-size: 1.2rem;
        }
        #home2-wtp-icon{
            height: 3.4rem;
            width:3.4rem;
            margin-left:18rem;
        }
        
        
    
       /* FEATURES SECTION */
       .home2-features-row{
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
        }
       .home2-features-card{
          padding:1.8rem;
          width:28%;
          height:25%;
        }
        .home2-features-card-top-left{
          width:30%;
          height:40%;
        }
        .home2-features-card-top-right{
          width:70%;
        }
       .home2-features-card-top-right h3{
         font-size: 2.2rem;
        }
       .home2-features-card-content p{
         font-size: 1.2rem;
        }
       #home-feature-card-icon{
        height:3.6rem;
        width:3rem;
        padding:0.5rem;
        }

        
/* NETWORK SECTION */
.home2-network-section{
  background-image: url("/public/home1/page5.png");
  background-position: center;
  height:120vh; 
  width:100%;
}
.home2-network-section h2{

  font-size: 2.2rem;
}
.home2-network-section p{
  font-size: 1.2rem;
}
.home2-network-container{
  display: flex;
  flex-direction: row;
  padding: 5rem;
 /* justify-content: space-between;*/
}
.home2-network-fcard{
width:45%;
margin-right: 3rem;
}
.home2-network-fcard-top{
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  padding: 1rem;
}
.home2-network-fcard-top-l{
  width:30%;
}
.home2-network-fcard-top-r{
  width:70%;
}
.home2-network-fcard-top-r h3{
  font-size: 2.2rem;
}
.home2-network-fcard-content{
  padding: 2rem;
  height:40vh;
  border-radius:15px;
  
}
.home2-network-fcard-content p{

}


   }
  
  /* Large devices (laptops/desktops, 980px and up) */
  @media (min-width:980px) and (max-width:1100px){
     
    /* HEADER SECTION */
    .home2-header-section{
        background-image: url("/public/home1/page1.png");
        background-position: center;
        height:74vh;
        width:100vw;
    }
      /* HEADER MAIN */
      .home2-header-main{
        padding: 4%;
    }
    .home2-header-main-left{
        width:70%;
    }
    .home2-header-main-right{
        width:30%;
    }
    .home2-header-main-left h1{
        font-size: 3.6rem;
    }
    .home2-header-main-left p{
        font-size: 1.1rem;
    }
    #home2-header-main-btn{
        width:11rem;
        padding: 0.7rem;
    }
    #home2-wtp-icon{
        height:3rem;
        width:3rem;
        margin-left:14rem;
    }

      /* FEATURES SECTION */
      .home2-features-row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
      }
     .home2-features-card{
        padding:1rem;
        width:28%;
        height:30%;
      }
      .home2-features-card-top-left{
        width:30%;
        height:40%;
      }
      .home2-features-card-top-right{
        width:70%;
      }
     .home2-features-card-top-right h3{
       font-size: 2.2rem;
      }
     .home2-features-card-content p{
       font-size: 1rem;
      }
     #home-feature-card-icon{
      height:3.4rem;
      width:3rem;
      padding:0.5rem;
      }

      /* NETWORK SECTION */
.home2-network-section{
  background-image: url("/public/home1/page5.png");
  background-position: center;
  height:120vh; 
  width:100%;
}
.home2-network-section h2{

  font-size: 2.2rem;
}
.home2-network-section p{
  font-size: 1.2rem;
}
.home2-network-container{
  display: flex;
  flex-direction: row;
  padding: 5rem;
 /* justify-content: space-between;*/
}
.home2-network-fcard{
width:45%;
margin-right: 3rem;
}
.home2-network-fcard-top{
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  padding: 1rem;
}
.home2-network-fcard-top-l{
  width:30%;
}
.home2-network-fcard-top-r{
  width:70%;
}
.home2-network-fcard-top-r h3{
  font-size: 2.2rem;
}
.home2-network-fcard-content{
  padding: 2rem;
  height:40vh;
  border-radius:15px;
  
}
.home2-network-fcard-content p{

}



  }
  
  /* Large devices (laptops/desktops, 860px and up) */
  @media (min-width:860px) and (max-width:980px){
    
    /* NAVBAR */
    #home2-navbar-default{
      display:none;
    }
    #home2-navbar-changed{
      display:block;
    }
    #home2-display-menu{
      display: none;
    }


    /* HEADER SECTION */
    .home2-header-section{
        background-image: url("/public/home1/page1.png");
        background-position: center;
        height:66vh;
        width:100vw;
    }
     /* HEADER MAIN */
     .home2-header-main{
        padding: 4%;
    }
    .home2-header-main-left{
        width:76%;
    }
    .home2-header-main-right{
        width:24%;
    }
    .home2-header-main-left h1{
        font-size: 3.2rem;
    }
    .home2-header-main-left p{
        font-size: 1rem;
    }
    #home2-header-main-btn{
        width:10rem;
        padding: 0.6rem;
    }
    #home2-wtp-icon{
        height:3rem;
        width:3rem;
        margin-left:11rem;
    }
    /* FEATURES SECTION */
      .home2-features-row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
      }
     .home2-features-card{
        padding:1rem;
        width:28%;
        height:30%;
      }
      .home2-features-card-top-left{
        width:30%;
        height:40%;
      }
      .home2-features-card-top-right{
        width:70%;
      }
     .home2-features-card-top-right h3{
       font-size: 1.8rem;
      }
     .home2-features-card-content p{
       font-size: 1rem;
      }
     #home-feature-card-icon{
      height:3rem;
      width:2.4rem;
      padding:0.5rem;
      }


      
/* MODULES */
/* SERVICES SECTION */
.home2-modules-section h1{
  font-size: 2.2rem;
}
.home2-modules-section p{
  font-size: 1.2rem;
}
/* SERVICES SECTION */
.home2-services-section{
  background-image: url("/public/home1/page3.png");
  background-position: center;
  height:136vh;
  width:100vw;
}
.home2-services-section-container{
display: flex;
flex-direction: column-reverse;
}
.home2-services-section-left{
  width:90%;
  margin-left: 5%;
  padding: 5%;
  text-align: left;
}
.home2-services-section-right{
  width:70%;
  margin-left: 15%;
}
.home2-services-section-left h2{
  font-size:2.2rem;
}
.home2-services-section-left p{
  font-size: 1.4rem;
}

/* TOOLS SECTION */
.home2-tools-section{
  background-image: url("/public/home1/page4.png");
  background-position: center;
/*  height:100vh; */
  width:100%;
}
.home2-tools-section h2{
  font-size: 2.2rem;
}
.home2-tools-section-container{
display: flex;
flex-direction: column;
}
.home2-tools-section-right{
  width:90%;
  margin-left: 5%;
  padding: 5%;
  text-align: left;
}
.home2-tools-section-left{
  width:80%;
  margin-left: 10%;
}

/* NETWORK SECTION */
.home2-network-section{
  background-image: url("/public/home1/page5.png");
  background-position: center;
  height:156vh; 
  width:100%;
}
.home2-network-section h2{

  font-size: 2rem;
}
.home2-network-section p{
  font-size: 1.2rem;
}
.home2-network-container{
  display: flex;
  flex-direction: column;
  padding: 4rem;
 /* justify-content: space-between;*/
}
.home2-network-fcard{
width:80%;
margin-left:10%;
}
.home2-network-fcard-top{
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  padding: 1rem;
}
.home2-network-fcard-top-l{
  width:30%;
}
.home2-network-fcard-top-r{
  width:70%;
}
.home2-network-fcard-top-r h3{
  font-size: 2rem;
}
.home2-network-fcard-content{
  padding: 2rem;
  height:40vh;
  border-radius:15px;
  
}
.home2-network-fcard-content p{
  font-size: 1.2rem;
} 


/* HIRING SECTION */
.home2-hiring-section{
  background-image: url("/public/home1/page6.png");
  background-position: center;
 /* height:100vh; */
  width:100%;
}
.home2-hiring-section h2{
  font-size: 2.2rem;
}
.home2-hiring-section p{
  font-size: 1.2rem;
}
.home2-hiring-container{
  display: flex;
  flex-direction: column;
}
.home2-hiring-section-right{
    width:90%;
    padding: 5%;
    text-align: left;
}
.home2-hiring-section-left{
    width:80%;
    margin-left: 10%;
}
.home2-hiring-card{
  width:96%;
  margin-left: 10%;
}
.home2-hiring-card-right h4{
  font-size:2rem;
}
.home2-hiring-card-right p{
  font-size: 1.2rem;
}



    
  }

  /* Large devices (laptops/desktops, 740px and up) */
  @media (min-width:740px) and (max-width:860px){
    
        /* NAVBAR */
        #home2-navbar-default{
          display:none;
        }
        #home2-navbar-changed{
          display:block;
        }
        #home2-display-menu{
          display: none;
        }

        /* HEADER SECTION */
    .home2-header-section{
        background-image: url("/public/home1/page1.png");
        background-position: center;
        height:66vh;
        width:100vw;
    }
    /* HEADER MAIN */
    .home2-header-main{
        padding: 3%;
    }
    .home2-header-main-left{
        width:80%;
    }
    .home2-header-main-right{
        width:20%;
    }
    .home2-header-main-left h1{
        font-size: 2.8rem;
    }
    .home2-header-main-left p{
        font-size: 1rem;
    }
    #home2-header-main-btn{
        width:9rem;
        padding: 0.6rem;
        font-size: 0.8rem;
    }
    #home2-wtp-icon{
        height:2.4rem;
        width:2.4rem;
        margin-left:7rem;
    }
        /* FEATURES SECTION */
        .home2-features-row{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
          }
         .home2-features-card{
            padding:1rem;
            width:28%;
            height:30%;
          }
          .home2-features-card-top-left{
            width:24%;
            height:34%;
          }
          .home2-features-card-top-right{
            width:76%;
          }
         .home2-features-card-top-right h3{
           font-size: 1.6rem;
          }
         .home2-features-card-content p{
           font-size: 1rem;
          }
         #home-feature-card-icon{
          height:2.4rem;
          width:2.4rem;
          padding:0.5rem;
          }

             
       /* MODULES */
       .home2-modules-section h1{
        font-size: 2rem;
      }
      .home2-modules-section p{
        font-size: 1rem;
      }
      /* SERVICES SECTION */
      .home2-services-section{
        background-image: url("/public/home1/page3.png");
        background-position: center;
        height:128vh;
        width:100vw;
      }
      .home2-services-section-container{
        display: flex;
        flex-direction: column-reverse;
      }
      .home2-services-section-left{
        width:90%;
        margin-left: 5%;
        padding: 5%;
        text-align: left;
      }
      .home2-services-section-right{
        width:70%;
        margin-left: 15%;
      }
      .home2-services-section-left h2{
        font-size:2rem;
      }
      .home2-services-section-left p{
        font-size: 1.2rem;
      }

      /* TOOLS SECTION */
      .home2-tools-section{
        background-image: url("/public/home1/page4.png");
        background-position: center;
      /*  height:100vh; */
        width:100%;
      }
      .home2-tools-section h2{
        font-size: 2rem;
      }
      .home2-tools-section-container{
        display: flex;
        flex-direction: column;
      }
      .home2-tools-section-right{
        width:90%;
        margin-left: 5%;
        padding: 5%;
        text-align: left;
      }
      .home2-tools-section-left{
        width:80%;
        margin-left: 10%;
      }
      #home2-tools-fcard-icon {
        height:4.5rem;
        width:4.5rem;
        margin-top:2.5rem;
        box-shadow:#b0b0b0 0.2rem 0.2rem 0.3rem 0.2rem;
        border-radius:15px;
      }
  
      
/* NETWORK SECTION */
.home2-network-section{
  background-image: url("/public/home1/page5.png");
  background-position: center;
  height:160vh; 
  width:100%;
}
.home2-network-section h2{

  font-size: 1.8rem;
}
.home2-network-section p{
  font-size: 1rem;
}
.home2-network-container{
  display: flex;
  flex-direction: column;
  padding: 3rem;
 /* justify-content: space-between;*/
}
.home2-network-fcard{
width:80%;
margin-left:10%;
}
.home2-network-fcard-top{
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  padding: 1rem;
}
.home2-network-fcard-top-l{
  width:30%;
}
.home2-network-fcard-top-r{
  width:70%;
}
.home2-network-fcard-top-r h3{
  font-size: 1.8rem;
}
.home2-network-fcard-content{
  padding: 2rem;
  height:36vh;
  border-radius:15px;
  
}
.home2-network-fcard-content p{
  font-size: 1rem;
} 


/* HIRING SECTION */
.home2-hiring-section{
  background-image: url("/public/home1/page6.png");
  background-position: center;
 /* height:100vh; */
  width:100%;
}
.home2-hiring-section h2{
  font-size: 2.2rem;
}
.home2-hiring-section p{
  font-size: 1.2rem;
}
.home2-hiring-container{
  display: flex;
  flex-direction: column;
}
.home2-hiring-section-right{
    width:90%;
    padding: 5%;
    text-align: left;
}
.home2-hiring-section-left{
    width:80%;
    margin-left: 10%;
}
.home2-hiring-card{
  width:96%;
  margin-left: 10%;
}
.home2-hiring-card-right h4{
  font-size:2rem;
}
.home2-hiring-card-right p{
  font-size: 1.2rem;
}



  }

  /* Large devices (laptops/desktops, 600px and up) */
  @media (min-width:600px) and (max-width:740px){
    

      /* NAVBAR */
      #home2-navbar-default{
        display:none;
      }
      #home2-navbar-changed{
        display:block;
      }
      #home2-display-menu{
        display: none;
      }

/* HEADER SECTION */
.home2-header-section{
    background-image: url("/public/home1/page1.png");
    background-position: center;
    height:64vh;
    width:100vw;
}
 /* HEADER MAIN */
 .home2-header-main{
    padding: 2%;
}
.home2-header-main-left{
    width:80%;
}
.home2-header-main-right{
    width:20%;
}
.home2-header-main-left h1{
    font-size: 2.4rem;
}
.home2-header-main-left p{
    font-size: 1rem;
}
#home2-header-main-btn{
    width:7.4rem;
    padding: 0.4rem;
    font-size: 0.8rem;
}
#home2-wtp-icon{
    height:2.4rem;
    width:2.4rem;
    margin-left:2rem;
}
  /* FEATURES SECTION */
  .home2-features-row{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
 .home2-features-card{
    padding:1rem;
    margin-left:10%;
    width:80%;
    height:50%;
    margin-top: 3rem;
  }
  .home2-features-card-top-left{
    width:28%;
    height:30%;
  }
  .home2-features-card-top-right{
    width:80%;
  }
 .home2-features-card-top-right h3{
   font-size: 2.5rem;
  }
 .home2-features-card-content p{
   font-size: 1.4rem;
  }
 #home-feature-card-icon{
  height:3.6rem;
  width:3.6rem;
  padding:0.5rem;
  }

     /* MODULES */
     .home2-modules-section h1{
      font-size: 1.8rem;
    }
    .home2-modules-section p{
      font-size: 1rem;
    }
      /* SERVICES SECTION */
      .home2-services-section{
        background-image: url("/public/home1/page3.png");
        background-position: center;
        height:120vh;
        width:100vw;
      }
      .home2-services-section-container{
        display: flex;
        flex-direction: column-reverse;
      }
      .home2-services-section-left{
        width:90%;
        margin-left: 5%;
        padding: 5%;
        text-align: left;
      }
      .home2-services-section-right{
        width:70%;
        margin-left: 15%;
      }
    /* TOOLS SECTION */
    .home2-tools-section{
      background-image: url("/public/home1/page4.png");
      background-position: center;
    /*  height:100vh; */
      width:100%;
    }
    .home2-tools-section h2{
      font-size: 1.8rem;
    }
    .home2-tools-section-container{
      display: flex;
      flex-direction: column;
    }
    .home2-tools-section-right{
      width:90%;
      margin-left: 5%;
      padding: 5%;
      text-align: left;
    }
    .home2-tools-section-left{
      width:80%;
      margin-left: 10%;
    }
    .home2-services-section-left h2{
      font-size:1.8rem;
    }
    .home2-services-section-left p{
      font-size: 1rem;
    }
    #home2-tools-fcard-icon {
      height:4rem;
      width:4rem;
      margin-top:2.5rem;
      box-shadow:#b0b0b0 0.2rem 0.2rem 0.3rem 0.2rem;
      border-radius:15px;
    }
    /* NETWORK SECTION */
.home2-network-section{
  background-image: url("/public/home1/page5.png");
  background-position: center;
  height:164vh; 
  width:100%;
}
.home2-network-section h2{

  font-size: 1.8rem;
}
.home2-network-section p{
  font-size: 1rem;
}
.home2-network-container{
  display: flex;
  flex-direction: column;
  padding: 3rem;
 /* justify-content: space-between;*/
}
.home2-network-fcard{
width:80%;
margin-left:10%;
}
.home2-network-fcard-top{
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  padding: 1rem;
}
.home2-network-fcard-top-l{
  width:30%;
}
.home2-network-fcard-top-r{
  width:70%;
}
.home2-network-fcard-top-r h3{
  font-size: 1.8rem;
}
.home2-network-fcard-content{
  padding: 2rem;
  height:36vh;
  border-radius:15px;
  
}
.home2-network-fcard-content p{
  font-size: 1rem;
} 


/* HIRING SECTION */
.home2-hiring-section{
  background-image: url("/public/home1/page6.png");
  background-position: center;
 /* height:100vh; */
  width:100%;
}
.home2-hiring-section h2{
  font-size: 2rem;
}
.home2-hiring-section p{
  font-size: 1rem;
}
.home2-hiring-container{
  display: flex;
  flex-direction: column;
}
.home2-hiring-section-right{
    width:90%;
    padding: 5%;
    text-align: left;
}
.home2-hiring-section-left{
    width:80%;
    margin-left: 10%;
}
.home2-hiring-card{
  width:96%;
  margin-left: 10%;
}
.home2-hiring-card-right h4{
  font-size:2rem;
}
.home2-hiring-card-right p{
  font-size: 1rem;
}

  }

 /* Large devices (laptops/desktops, 480px and up) */
 @media (min-width:480px) and (max-width:600px){
    
      /* NAVBAR */
      #home2-navbar-default{
        display:none;
      }
      #home2-navbar-changed{
        display:block;
      }
      #home2-display-menu{
        display: none;
      }

/* HEADER SECTION */
.home2-header-section{
   background-image: url("/public/home1/page1.png");
   background-position: center;
   height:64vh;
   width:100vw;
}
      
/* HEADER MAIN */
 .home2-header-main{
    padding: 2%;
}
.home2-header-main-left{
    width:86%;
}
.home2-header-main-right{
    width:14%;
}
.home2-header-main-left h1{
    font-size: 2rem;
}
.home2-header-main-left p{
    font-size: 1rem;
}
#home2-header-main-btn{
    width:6rem;
    padding: 0.3rem;
    font-size: 0.8rem;
    border-radius: 7px;
}
#home2-wtp-icon{
    height:1.8rem;
    width: 1.8rem;
    margin-left:0;
}

/* FEATURES SECTION */
.home2-features-row{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
 .home2-features-card{
    padding:1rem;
    margin-left:10%;
    width:80%;
    height:50%;
    margin-top: 3rem;
  }
  .home2-features-card-top-left{
    width:28%;
    height:30%;
  }
  .home2-features-card-top-right{
    width:80%;
  }
 .home2-features-card-top-right h3{
   font-size: 2.5rem;
  }
 .home2-features-card-content p{
   font-size: 1.4rem;
  }
 #home-feature-card-icon{
  height:3.6rem;
  width:3.6rem;
  padding:0.5rem;
  }

     /* MODULES */
     .home2-modules-section h1{
      font-size: 1.8rem;
    }
    .home2-modules-section p{
      font-size: 1rem;
    }
      /* SERVICES SECTION */
      .home2-services-section{
        background-image: url("/public/home1/page3.png");
        background-position: center;
        height:108vh;
        width:100vw;
      }
      .home2-services-section-container{
        display: flex;
        flex-direction: column-reverse;
      }
      .home2-services-section-left{
        width:90%;
        margin-left: 5%;
        padding: 5%;
        text-align: left;
      }
      .home2-services-section-right{
        width:70%;
        margin-left: 15%;
      }
      .home2-services-features-list{
        padding: 0.2rem;
        display: flex;
        flex-direction: row;
        align-items: left;
        margin-top: 2rem;
      }
      .home2-services-fcard{
        display: flex;
        flex-direction: row;
        padding: 0.2rem;
      }
      .home2-services-section-left h2{
        font-size:1.8rem;
      }
      .home2-services-section-left p{
        font-size: 1rem;
      }

          /* TOOLS SECTION */
          .home2-tools-section{
            background-image: url("/public/home1/page4.png");
            background-position: center;
          /*  height:100vh; */
            width:100%;
          }
          .home2-tools-section h2{
            font-size: 1.8rem;
          }
          .home2-tools-section-container{
            display: flex;
            flex-direction: column;
          }
          .home2-tools-section-right{
            width:90%;
            margin-left: 5%;
            padding: 5%;
            text-align: left;
          }
          .home2-tools-section-left{
            width:80%;
            margin-left: 10%;
          }
          #home2-tools-fcard-icon {
            height:4rem;
            width:4rem;
            margin-top:2.5rem;
            box-shadow:#b0b0b0 0.2rem 0.2rem 0.3rem 0.2rem;
            border-radius:15px;
          }

          /* NETWORK SECTION */
.home2-network-section{
  background-image: url("/public/home1/page5.png");
  background-position: center;
  height:190vh; 
  width:100%;
}
.home2-network-section h2{

  font-size: 1.8rem;
}
.home2-network-section p{
  font-size: 1rem;
}
.home2-network-container{
  display: flex;
  flex-direction: column;
  padding: 3rem;
 /* justify-content: space-between;*/
}
.home2-network-fcard{
width:80%;
margin-left:10%;
}
.home2-network-fcard-top{
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  padding: 1rem;
}
.home2-network-fcard-top-l{
  width:30%;
}
.home2-network-fcard-top-r{
  width:70%;
}
.home2-network-fcard-top-r h3{
  font-size: 1.8rem;
}
.home2-network-fcard-content{
  padding: 2rem;
  height:36vh;
  border-radius:15px;
  
}
.home2-network-fcard-content p{
  font-size: 1rem;
} 



/* HIRING SECTION */
.home2-hiring-section{
  background-image: url("/public/home1/page6.png");
  background-position: center;
 /* height:100vh; */
  width:100%;
}
.home2-hiring-section h2{
  font-size: 1.8rem;
}
.home2-hiring-section p{
  font-size: 1rem;
}
.home2-hiring-container{
  display: flex;
  flex-direction: column;
}
.home2-hiring-section-right{
    width:90%;
    padding: 5%;
    text-align: left;
}
.home2-hiring-section-left{
    width:80%;
    margin-left: 10%;
}
.home2-hiring-card{
  width:96%;
  margin-left: 10%;
}
.home2-hiring-card-right h4{
  font-size:1.8rem;
}
.home2-hiring-card-right p{
  font-size: 1rem;
}
#home2-hiring-card-icon{
  height:4rem;
  width:4rem;
  margin-right:1rem;
}

 }

  /* Large devices (laptops/desktops, 360px and up) */
  @media (min-width:360px) and (max-width:480px){

        /* NAVBAR */
        #home2-navbar-default{
          display:none;
        }
        #home2-navbar-changed{
          display:block;
        }
        #home2-display-menu{
          display: none;
        }

/* HEADER SECTION */
.home2-header-section{
    background-image: url("/public/home1/page1.png");
    background-position: center;
    height:70vh;
    width:100vw;
}
    /* HEADER MAIN */
 .home2-header-main{
    padding: 1%;
}
.home2-header-main-left{
    width:86%;
}
.home2-header-main-right{
    width:14%;
}
.home2-header-main-left h1{
    font-size: 1.6rem;
}
.home2-header-main-left p{
    font-size: 1rem;
}
#home2-header-main-btn{
    width:6rem;
    padding: 0.2rem;
    font-size: 0.7rem;
    border-radius: 7px;
}
#home2-wtp-icon{
    height:1.8rem;
    width: 1.8rem;
    margin-left:0rem;
}

/* FEATURES SECTION */
.home2-features-row{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
 .home2-features-card{
    padding:1rem;
    margin-left:10%;
    width:80%;
    height:50%;
    margin-top: 3rem;
  }
  .home2-features-card-top-left{
    width:28%;
    height:30%;
  }
  .home2-features-card-top-right{
    width:80%;
  }
 .home2-features-card-top-right h3{
   font-size: 2.1rem;
  }
 .home2-features-card-content p{
   font-size: 1.2rem;
  }
 #home-feature-card-icon{
  height:3.6rem;
  width:3.6rem;
  padding:0.5rem;
  }

    /* MODULES */
    .home2-modules-section h1{
      font-size: 1.6rem;
    }
    .home2-modules-section p{
      font-size: 1rem;
    }
      /* SERVICES SECTION */
      .home2-services-section{
        background-image: url("/public/home1/page3.png");
        background-position: center;
        height:120vh;
        width:100vw;
      }
      .home2-services-section-container{
        display: flex;
        flex-direction: column-reverse;
      }
      .home2-services-section-left{
        width:90%;
        margin-left: 5%;
        padding: 5%;
        text-align: left;
      }
      .home2-services-section-right{
        width:70%;
        margin-left: 15%;
      }
      .home2-services-features-list{
        padding: 0.2rem;
        display: flex;
        flex-direction: column;
        align-items: left;
        margin-top: 2rem;
      }
      .home2-services-fcard{
        display: flex;
        flex-direction: row;
        padding: 0.2rem;
      }
      .home2-services-section-left h2{
        font-size:1.6rem;
      }
      .home2-services-section-left p{
        font-size: 1rem;
      }

          /* TOOLS SECTION */
          .home2-tools-section{
            background-image: url("/public/home1/page4.png");
            background-position: center;
          /*  height:100vh; */
            width:100%;
          }
          .home2-tools-section h2{
            font-size: 1.6rem;
          }
          .home2-tools-section-container{
            display: flex;
            flex-direction: column;
          }
          .home2-tools-section-right{
            width:90%;
            margin-left: 5%;
            padding: 5%;
            text-align: left;
          }
          .home2-tools-section-left{
            width:80%;
            margin-left: 10%;
          }
          #home2-tools-fcard-icon {
            height:3.6rem;
            width:3.6rem;
            margin-top:2.5rem;
            box-shadow:#b0b0b0 0.2rem 0.2rem 0.3rem 0.2rem;
            border-radius:15px;
          }

          /* NETWORK SECTION */
.home2-network-section{
  background-image: url("/public/home1/page5.png");
  background-position: center;
  height:184vh; 
  width:100%;
}
.home2-network-section h2{

  font-size: 1.6rem;
}
.home2-network-section p{
  font-size: 1rem;
}
.home2-network-container{
  display: flex;
  flex-direction: column;
  padding: 2rem;
 /* justify-content: space-between;*/
}
.home2-network-fcard{
width:80%;
margin-left:10%;
}
.home2-network-fcard-top{
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  padding: 1rem;
}
.home2-network-fcard-top-l{
  width:30%;
}
.home2-network-fcard-top-r{
  width:70%;
}
.home2-network-fcard-top-r h3{
  font-size: 1.6rem;
}
.home2-network-fcard-content{
  padding: 1rem;
  height:44vh;
  border-radius:15px;
  
}
.home2-network-fcard-content p{
  font-size: 1rem;
} 


/* HIRING SECTION */
.home2-hiring-section{
  background-image: url("/public/home1/page6.png");
  background-position: center;
 /* height:100vh; */
  width:100%;
}
.home2-hiring-section h2{
  font-size: 1.8rem;
}
.home2-hiring-section p{
  font-size: 1rem;
}
.home2-hiring-container{
  display: flex;
  flex-direction: column;
}
.home2-hiring-section-right{
    width:90%;
    padding: 5%;
    text-align: left;
}
.home2-hiring-section-left{
    width:80%;
    height:70%;
    margin-left: 10%;
}
.home2-hiring-card{
  width:96%;
  margin-left: 10%;
}
.home2-hiring-card-right h4{
  font-size:1.8rem;
}
.home2-hiring-card-right p{
  font-size: 1rem;
}
#home2-hiring-card-icon{
  height:3.4rem;
  width:3.4rem;
  margin-right:0.5rem;
}


  }

    /* Large devices (laptops/desktops, 240px and up) */
    @media (min-width:240px) and (max-width:360px){
    
          /* NAVBAR */
    #home2-navbar-default{
      display:none;
    }
    #home2-navbar-changed{
      display:block;
    }
    #home2-display-menu{
      display: none;
    }

    /* HEADER SECTION */
    .home2-header-section{
        background-image: url("/public/home1/page1.png");
        background-position: center;
        height:54vh;
        width:100vw;
    }

        /* HEADER MAIN */
     .home2-header-main{
        padding: 0.5%;
    }
    .home2-header-main-left{
        width:90%;
    }
    .home2-header-main-right{
        width:10%;
    }
    .home2-header-main-left h1{
        font-size: 1.4rem;
    }
    .home2-header-main-left p{
        font-size: .7rem;
    }
    #home2-header-main-btn{
        width:5rem;
        padding: 0.2rem;
        font-size: 0.7rem;
        border-radius: 7px;
    }
    #home2-wtp-icon{
        height:1.2rem;
        width:1.2rem;
        margin-left:0rem;
    }

    /* FEATURES SECTION */
  .home2-features-row{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
 .home2-features-card{
    padding:1rem;
    margin-left:10%;
    width:80%;
    height:50%;
    margin-top: 3rem;
  }
  .home2-features-card-top-left{
    width:28%;
    height:30%;
  }
  .home2-features-card-top-right{
    width:80%;
  }
 .home2-features-card-top-right h3{
   font-size: 1.8rem;
  }
 .home2-features-card-content p{
   font-size: 1rem;
  }
 #home-feature-card-icon{
  height:3.6rem;
  width:3.6rem;
  padding:0.5rem;
  }
    /* MODULES */
    .home2-modules-section h1{
      font-size: 1.6rem;
    }
    .home2-modules-section p{
      font-size: .8rem;
    }
      /* SERVICES SECTION */
      .home2-services-section{
        background-image: url("/public/home1/page3.png");
        background-position: center;
        height:116vh;
        width:100vw;
      }
      .home2-services-section-container{
        display: flex;
        flex-direction: column-reverse;
      }
      .home2-services-section-left{
        width:90%;
        margin-left: 5%;
        padding: 5%;
        text-align: left;
      }
      .home2-services-section-right{
        width:70%;
        margin-left: 15%;
      }
      .home2-services-features-list{
        padding: 0.2rem;
        display: flex;
        flex-direction: column;
        align-items: left;
        margin-top: 2rem;
      }
      .home2-services-fcard{
        display: flex;
        flex-direction: row;
        padding: 0.2rem;
      }
      .home2-services-section-left h2{
        font-size:1.6rem;
      }
      .home2-services-section-left p{
        font-size: 0.8rem;
      }

      
          /* TOOLS SECTION */
          .home2-tools-section{
            background-image: url("/public/home1/page4.png");
            background-position: center;
          /*  height:100vh; */
            width:100%;
          }
          .home2-tools-section h2{
            font-size: 1.6rem;
          }
          .home2-tools-section-container{
            display: flex;
            flex-direction: column;
          }
          .home2-tools-section-right{
            width:90%;
            margin-left: 5%;
            padding: 5%;
            text-align: left;
          }
          .home2-tools-section-left{
            width:80%;
            margin-left: 10%;
          }
          #home2-tools-fcard-icon {
            height:3.2rem;
            width:3.2rem;
            margin-top:2.5rem;
            box-shadow:#b0b0b0 0.2rem 0.2rem 0.3rem 0.2rem;
            border-radius:15px;
          }

/* NETWORK SECTION */
.home2-network-section{
  background-image: url("/public/home1/page5.png");
  background-position: center;
  height:170vh; 
  width:100%;
}
.home2-network-section h2{

  font-size: 1.6rem;
}
.home2-network-section p{
  font-size: 0.8rem;
}
.home2-network-container{
  display: flex;
  flex-direction: column;
  padding: 1rem;
 /* justify-content: space-between;*/
}
.home2-network-fcard{
width:80%;
margin-left:10%;
}
.home2-network-fcard-top{
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  padding: 1rem;
}
.home2-network-fcard-top-l{
  width:30%;
}
.home2-network-fcard-top-r{
  width:70%;
}
.home2-network-fcard-top-r h3{
  font-size: 1.6rem;
}
.home2-network-fcard-content{
  padding: 1rem;
  height:36vh;
  border-radius:15px;
  
}
.home2-network-fcard-content p{
  font-size: 0.8rem;
} 

/* HIRING SECTION */
.home2-hiring-section{
  background-image: url("/public/home1/page6.png");
  background-position: center;
 /* height:100vh; */
  width:100%;
}
.home2-hiring-section h2{
  font-size: 1.8rem;
}
.home2-hiring-section p{
  font-size: 1rem;
}
.home2-hiring-container{
  display: flex;
  flex-direction: column;
}
.home2-hiring-section-right{
    width:90%;
    padding: 5%;
    text-align: left;
}
.home2-hiring-section-left{
    width:80%;
    height:70%;
    margin-left: 10%;
}
.home2-hiring-card{
  width:96%;
  margin-left: 10%;
}
.home2-hiring-card-right h4{
  font-size:1.8rem;
}
.home2-hiring-card-right p{
  font-size: 1rem;
}
#home2-hiring-card-icon{
  height:3.4rem;
  width:3.4rem;
  margin-right:0.5rem;
}


      }

    /* Large devices (laptops/desktops, 120px and up) */
    @media (min-width:120px) and (max-width:240px){
    
    /* NAVBAR */
    #home2-navbar-default{
      display:none;
    }
    #home2-navbar-changed{
      display:block;
    }
    #home2-display-menu{
      display: none;
    }

     /* HEADER SECTION */
    .home2-header-section{
        background-image: url("/public/home1/page1.png");
        background-position: center;
        height:70vh;
        width:100vw;
      }
        /* HEADER MAIN */
     .home2-header-main{
        padding: 0.5%;
    }
    .home2-header-main-left{
        width:90%;
    }
    .home2-header-main-right{
        width:10%;
    }
    .home2-header-main-left h1{
        font-size: 1.4rem;
    }
    .home2-header-main-left p{
        font-size: .7rem;
    }
    #home2-header-main-btn{
        width:5rem;
        padding: 0.2rem;
        font-size: 0.7rem;
        border-radius: 7px;
    }
    #home2-wtp-icon{
        height: 1.2rem;
        width: 1.2rem;
        margin-left:0rem;
    }
    
      /* FEATURES SECTION */
  .home2-features-row{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
 .home2-features-card{
    padding:1rem;
    margin-left:10%;
    width:80%;
    height:50%;
    margin-top: 3rem;
  }
  .home2-features-card-top-left{
    width:28%;
    height:30%;
  }
  .home2-features-card-top-right{
    width:80%;
  }
 .home2-features-card-top-right h3{
   font-size: 1.8rem;
  }
 .home2-features-card-content p{
   font-size: 1rem;
  }
 #home-feature-card-icon{
  height:3.6rem;
  width:3.6rem;
  padding:0.5rem;
  }

      /* MODULES */
      .home2-modules-section h1{
        font-size: 1.6rem;
      }
      .home2-modules-section p{
        font-size: 0.8rem;
      }
      /* SERVICES SECTION */
      .home2-services-section{
        background-image: url("/public/home1/page3.png");
        background-position: center;
        height:160vh;
        width:100vw;
      }
      .home2-services-section-container{
        display: flex;
        flex-direction: column-reverse;
      }
      .home2-services-section-left{
        width:90%;
        margin-left: 5%;
        padding: 5%;
        text-align: left;
      }
      .home2-services-section-right{
        width:70%;
        margin-left: 15%;
      }
      .home2-services-features-list{
        padding: 0.2rem;
        display: flex;
        flex-direction: column;
        align-items: left;
        margin-top: 2rem;
      }
      .home2-services-fcard{
        display: flex;
        flex-direction: row;
        padding: 0.2rem;
      }
      .home2-services-section-left h2{
        font-size:1.6rem;
      }
      .home2-services-section-left p{
        font-size: 0.8rem;
      }

      /* TOOLS SECTION */
      .home2-tools-section{
        background-image: url("/public/home1/page4.png");
        background-position: center;
      /*  height:100vh; */
        width:100%;
      }
      .home2-tools-section h2{
        font-size: 1.6rem;
      }
      .home2-tools-section-container{
        display: flex;
        flex-direction: column;
      }
      .home2-tools-section-right{
        width:90%;
        margin-left: 5%;
        padding: 5%;
        text-align: left;
      }
      .home2-tools-section-left{
        width:80%;
        margin-left: 10%;
      }
      #home2-tools-fcard-icon {
        height:3rem;
        width:3rem;
        margin-top:2.5rem;
        box-shadow:#b0b0b0 0.2rem 0.2rem 0.3rem 0.2rem;
        border-radius:15px;
      }

      
/* NETWORK SECTION */
.home2-network-section{
  background-image: url("/public/home1/page5.png");
  background-position: center;
  height:180vh; 
  width:100%;
}
.home2-network-section h2{

  font-size: 1.6rem;
}
.home2-network-section p{
  font-size: 0.8rem;
}
.home2-network-container{
  display: flex;
  flex-direction: column;
  padding: 1rem;
 /* justify-content: space-between;*/
}
.home2-network-fcard{
width:80%;
margin-left:10%;
}
.home2-network-fcard-top{
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  padding: 1rem;
}
.home2-network-fcard-top-l{
  width:30%;
}
.home2-network-fcard-top-r{
  width:70%;
}
.home2-network-fcard-top-r h3{
  font-size: 1.6rem;
}
.home2-network-fcard-content{
  padding: 1rem;
  height:36vh;
  border-radius:15px;
  
}
.home2-network-fcard-content p{
  font-size: 0.8rem;
} 



      }