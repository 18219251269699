
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Hedvig+Letters+Serif:opsz@12..24&family=Inter+Tight:wght@200&family=Nunito:ital@1&family=Roboto:ital@1&family=Secular+One&display=swap');


html{


}

body{

    font-size:67%;

}

p{
    font-family: 'Roboto', serif;
}
h2{
font-family: 'Courgette', Arial, Helvetica, sans-serif;
/*font-family: 'Hedvig Letters Serif', serif;
font-family: 'Inter Tight', sans-serif;
font-family: 'Nunito', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Secular One', sans-serif;*/
}
h3{
font-family: 'Hedvig Letters Serif', serif;
/*font-family: 'Inter Tight', sans-serif;
font-family: 'Nunito', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Secular One', sans-serif;*/
}
h4{
font-family: 'Inter Tight', sans-serif;
  /*  font-family: 'Nunito', sans-serif;
    font-family: 'Roboto', sans-serif;
    font-family: 'Secular One', sans-serif;*/
}
h5{
    font-family: 'Nunito', sans-serif;
 /*   font-family: 'Roboto', sans-serif;
    font-family: 'Secular One', sans-serif;*/
}
h6{
    font-family: 'Roboto', sans-serif;
    /* font-family: 'Secular One', sans-serif;*/
}
h1{
    font-family: 'Secular One', sans-serif;
}



/* HEADER SECTION */
.header-section-h{
 /*   background-image: linear-gradient(to right, rgb(49, 79, 145), rgb(15, 11, 81)); */
 /*   background-color: blue; */
 background-color: #cbcbcb;
    height:70vh;
    width:100vw;
}
.header-section-h-display{
    display:flex;
    flex-direction: row;
}
.header-section-h-left{
    padding:4rem;
    width: 50%;
    text-align: left;
}
.header-section-h-left h1{
    margin-top: 4rem;
    font-family: 'Hedvig Letters Serif', serif;
    color:#000000;
    text-align:left;
}
.header-section-h-left p{
    margin-top: 1rem;
    font-family: 'Roboto', sans-serif;
    color:#4c4c4c;
    text-align:left;
}
.header-h-btn{
    background-color: #1cd2d6;
    box-shadow: 1px 2px 2px 1px #dfdfdf;
    border:#000000;
    font-weight: bold;
    padding: 0.8rem;
    border-radius: 10px;
}
.header-section-h-right{
    padding:4rem;
    padding: 1rem;
    width: 50%;
}
#header-img{
    margin-top:10%;
    height:80%;
    margin-left:10%;
    width:80%;
    border-radius: 15px;

}

/* AFTER HEADER SECTION */
.after-header{
    width:96%;
    margin-left:2%;

}
.after-header h2{
    font-family: 'Hedvig Letters Serif', serif;
    font-weight: bold;
}
.after-header-heading-list{
   padding:4rem;
   display: flex;
   flex-direction: row;
} 
.header-list-heading-left{
  /* background-color: #073f40;*/
   padding: 0.8rem;
   color: #000000;
   border-radius: 25px;
   box-shadow: 2px 3px 3px 2px #cbcbcb;
}

.header-list-left{
  width:50%;
  padding: 2rem;
}
.header-list-right{
    width:50%;
    padding: 2rem;
  }