

 /****************** ANIMATIONS ******************/
 
 /* Fade In Animation for Sidebar */
 @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
   /* Slide In Animation for Left Sidebar */
@keyframes slideInFromLeft {
    from {
        left: -80vw;
        width: 0;
      }
      to {
        left: 0;
        width: 80vw;
      }
  }