
/* @media only screen and (min-width: 1200px) {} */
html{
    font-size: 100%;
}
body{
    font-size: 2rem;
}

/* PAGE */
.home-container{
    /* background-color: #013141; */
    background-color: #ffffff;
    width:100vw;
    height:100vh;
    
}


/* HEADER */

/* NAVBAR */
.homesyle-nav{
/*color: #ffffff;*/
color: #04062a;

}
.navbar-custom{

     color:#04062a; 
   }
   .nav-link li{
     color:#04062a; 

   }
   .nav-link:hover{

   }
   .get-started-top{
   background-color: #094359;
  /* color:#ffffff; */
   color: #04062a;
  }

  /* HEADER SECTION */
  .header-section{
    width: 100%;
    
  }
  .header-content{
  width: 60vw;
  }
  .main-header-sub{
    margin-top: 2rem;
    text-align: center;
    
    
  }
  .main-header-sub h1{
    font-size: 3rem;
    margin-left: 10%;
    text-align: center;
    /*color: #ffffff;*/
    color: #04062a;
  }
  .hd-para{
    margin-top: 2rem;
    text-align: center;
    margin-left: 5rem;
    margin-right: 5rem;
   /* color:#afbbc4; */
    color: #04062a;
  }
  .hd-para p{
    font-size: 1rem;
   /* color:#cbd1d5; */
    color: #04062a;
  }

  /* Search Bar */
  .search-bar{
    margin-top: 3rem;
    width:60%;
    margin-left: 20%;
    border: 0.05rem solid #007bb6;
  }
  #search-btn{
    background-color: /*rgb(255, 0, 81)*/ rgb(0, 128, 255);
    color: #ffffff;
  }


/* Line */
.line{
  width:100vw;
  padding: 0.01rem;
  background-color: #cbd1d5;
}



  /*  WHATSAPP CHAT ICON */
  .whatsapp-section{
   /* margin-top: 92%; */

   
  
  }
  #whatsapp-icon{
    height:3rem;
    width: 3rem;
    margin-left: 2rem;
    position: fixed;
    margin-top: 90vh;
    margin-left: 92vw;
  }


  /* EXTRA CODE  */

  /* Searchbar Suggestions */
  .searchbar-suggestions{

  }
/* Searchbar Dropdown */
.searchbar-dropdown{
    background-color: #0a3251;
    width:60vw;
    margin-left: 20vw;
    margin-top: 10vh;
    position: fixed;
 
}

/* EXTRAS */

/* Suggestion Box */
.suggestion-box-section{
  width:70%;
  margin-left: 15%;
  margin-top: 1rem;
  font-size: 1rem;
}
#suggestion-box-1{
  margin-top: 1rem;
  background-color: #e4f058;
  color:#04062a;
  box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem #afb6ba;
  margin-left: 2rem;
  font-size: 1rem;
}
#suggestion-box-2{
  margin-top: 1rem;
  background-color: #58f0b6;
  color:#04062a;
  box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem #afb6ba;
  margin-left: 2rem;
  font-size: 1rem;
}
#suggestion-box-3{
  margin-top: 1rem;
  background-color: #eaeaea;
  color:#04062a;
  box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem #afb6ba;
  margin-left: 2rem;
  font-size: 1rem;
}
#suggestion-box-4{
  margin-top: 1rem;
  background-color: /*#d295ae*/ #023149;
  color:#ffffff;
  box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem #afb6ba;
  margin-left: 2rem;
  font-size: 1rem;
}
#v-all{
  margin-left: 2rem;
  color:#094359;
}

/* FEATURES SECTION */
.features-section{

margin-top: 1rem;
}

.features-card-list{
  display: flex;
  flex-direction: row;
margin-top: 1rem;
border-top:0.1rem solid #094359;
width:70%;
margin-left: 15%;

}
/* Features Card */
.feature-card{
  margin-top: 2rem;
display: flex;
flex-direction: row;
width:30%;
margin-left: 3%;
border-radius: 12px;
}
.fc-left{
  /*width:30%;
  height:70%;*/
}
.fc-right{

}
.fc-right h4{
  font-size: 1.4rem;
  color:#39a95c;
}
.fc-right p{
  font-size: 0.8rem;
  color:#094359
}
.fc-right h3{
  font-weight: 600;
  font-size: 1.6rem;
}
#feature-icon{
  height:3rem;
  width:3rem;

}
#card-sub{
  text-align: center;
  font-weight: 400;
  background-color: #fafdff/*#0a3554*/;
  padding: 1rem;
  color: #153e62;
  text-align: center;
  box-shadow: 0.2rem 0rem #023962;
}
#crd-str{
  width:1.5rem;
  height:1.5rem;

}


/* ABOUT SECTION */
.about-section{
  margin-top: 2rem;
display: flex;
flex-direction: row;
width:96vw;
margin-left: 2vw;
}

.about-section-left{
width:45%;
margin-left: 3%;
margin-right: 2%;

}
.about-section-right{
  width:45%;
  margin-left: 2%;
  margin-right: 3%;

}
.about-section-left h3{
  font-size: 2.4rem;
}
.about-section-left ul{
  margin-top: 2rem;
}
.about-section-left li{
  list-style-type: none;
  text-align: left;
}
#about-list-icon{
  margin-top: 1rem;
height:3rem;
width:3rem;
}
#about-list-heading{
  margin-top: 1rem;
font-weight: bold;
margin-left: 2rem;
font-size: 1.4rem;
}
#about-list-content{
margin-left: 5rem;
}


/* Customer Testimonials */

.home-testimonial{background-color: #231834;height: 380px}.home-testimonial-bottom{background-color: #f8f8f8;transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;margin-top: 20px;margin-bottom: 0px;position: relative;height: 130px;top: 190px}.home-testimonial h3{color: var(--orange);font-size: 14px;font-weight: 500;text-transform: uppercase}.home-testimonial h2{color: white;font-size: 28px;font-weight: 700}.testimonial-inner{position: relative;top: -174px}.testimonial-pos{position: relative;top: 24px}.testimonial-inner .tour-desc{border-radius: 5px;padding: 40px}.color-grey-3{font-family: "Montserrat", Sans-serif;font-size: 14px;color: #6c83a2}.testimonial-inner img.tm-people{width: 60px;height: 60px;-webkit-border-radius: 50%;border-radius: 50%;-o-object-fit: cover;object-fit: cover;max-width: none}.link-name{font-family: "Montserrat", Sans-serif;font-size: 14px;color: #6c83a2}.link-position{font-family: "Montserrat", Sans-serif;font-size: 12px;color: #6c83a2}



/* FOOTER */
/*
  =========================================================================================
                                    Social Icons
  =========================================================================================
  */
  
  
  .round-btn {display: inline;height: 40px; width: 40px; background:#fff;border-radius: 50%;float: left;margin: 15px 8px;box-shadow: 2px 2px 5px 0px rgb(82, 0, 67);border: 1px solid;/*border: 1px solid #622657;*/}
  .round-btn a {display: block !important;padding: 7px 12px;font-size: 18px;border-radius: 50%;}
  .round-btn .icon {padding: 3px;}
  .round-btn .icon img{height: 24px; width: 32px;margin-top: 6px;}
  .btn-facebook a {color: #3b5998;padding: 8px 13px;}
  .btn-linkedin a {color: #007bb6;}
  .btn-twitter a{color: #1c9deb;}
  .btn-instagram a{color: #dd3f5c;}
  .btn-whatsapp a{color: #155E54;}
  .btn-envelop a{color: #D6403A;font-size: 15px; padding: 9px 12px;}
  .standard_header .standard_social_links {margin-left: 1rem;}
  
    /*
    =========================================================================================
                                      footer
    =========================================================================================
    */
    
    .footer-wrap {
      padding-top: 5rem;
      margin-top: 2rem;
      background-size: cover;
      background-color: #023149;
  }
  #footer-sm-icons{
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 1rem;

  }
  .footer-wrap h3 {
      color: #fff;
      font-size: 17px;
      text-transform: uppercase;
      margin-bottom: 25px;

  }
  
  .footer-wrap p {
      font-size: 13px;
      line-height: 24px;
      color: #b3b3b3;
      margin-top: 15px;
  }
  
  .footer-wrap p a {
      color: #fff;
      text-decoration: underline;
      font-style: italic;
  }
  
  .footer-wrap p a:hover {
      text-decoration: none;
      color: #ff7800;
  }
  
  .footer-links li a {
      font-size: 13px;
      line-height: 30px;
      color: #ccc;
      text-decoration: none;
  }
  
  
  .footer-links li:before {
    /*  content: "\f105"; */
      font-family: 'FontAwesome';
      padding-right: 10px;
      color: #ccc;

  }
  
  .footer-category li a {
      font-size: 13px;
      line-height: 30px;
      color: #ccc;
      text-decoration: none;
  }
  
  .footer-category li:before {
    /*  content: "\f105"; */
      font-family: 'FontAwesome';
      padding-right: 10px;
      color: #b3b3b3;
  }
  
  .address {
      
      color: #b3b3b3;
      font-size: 14px;
      position: relative;
      padding-left: 30px;
      line-height: 30px;
  }
  
  .address:before {
      content: "\f277";
      font-family: 'FontAwesome';
      position: absolute;
      top: 0;
      left: 0;
  }
  
  .info a {
   
      color: #b3b3b3;
      font-size: 14px;
      line-height: 30px;
      font-weight: normal;
  }
  
  .fa-phone:before {
      content: "\f095";
  }
  
  .info a {
  
      color: #b3b3b3;
      font-size: 14px;
      line-height: 30px;
      font-weight: normal;
  }
  
  .fa-fax:before {
      content: "\f1ac";
  }
  
  .copyright {
      border-top: 1px solid #111;
      font-size: 14px;
      color: #ccc;
      padding-top: 15px;
      text-align: center;
      padding-bottom: 15px;
      background: #222;
  }
  footer .second_class{
      border-bottom: 1px solid #444;
      padding-bottom: 25px;
  }
  footer .first_class {
      padding-bottom: 21px;
      border-bottom: 1px solid #444;
  }
  footer .first_class p, footer .first_class h3{
      margin: 0 0;
      
  }
  footer{
      background: #333;

  }
  
  footer .newsletter input[type="text"] {
      width: 100%;
      background: #fff;
      color: #333;
      border: 1px solid #222;
      padding: 14px 20px;
      border-radius: 50px;
      margin-top: 12px;
  }
  
  .newsletter .newsletter_submit_btn {
      background: #fff;
      position: absolute;
      right: 30px;
      border: 0;
      top: 26px;
      font-size: 17px;
      color: #333;
  }
  
  
  footer .second_class_bdr{
      padding-top: 25px;
      border-top:1px solid #222;
  }
  
  footer .btn-facebook a {
      padding: 6px 14px !important;
  }
  
  footer .btn-envelop a {
      color: #D6403A;
      font-size: 15px;
      padding: 12px 12px;
  }
  
  footer .round-btn a {
      padding: 6px 12px;
  }
  
  footer .round-btn {
      box-shadow: 2px 2px 5px 0px #222 !important;}
  
  footer .round-btn {
      margin: 15px 4px;}
    
  footer dl, ol, ul {
      padding-left: 5px;
  }
    footer li{
      list-style: none;
      text-align: left;
    }
  
  @media(max-width:768px){
      .footer-wrap h3 {
      margin-top: 27px;}
      
      footer .round-btn {
      margin: 15px 4px;}
  }
  @media(max-width:320px){
  .copyright {
      font-size: 13px;}
  } 


  /* Header section flex */
  .header-section{
   /* display: flex;
    flex-direction: row;
    width: 100%; */
  }

  /*  CONTACT FORM */
  .contact-form{
    width:28%;
    margin-left: 6%;
  /*  height: 56vh; */
    margin-top: 6vh;
  /*  border : 0.2rem solid #a5b8c9; */
    background-color: #044680;
    border-radius: 15px;
    box-shadow: .1rem .3rem .2rem #b1b9bf;
  }
  .contact-form h5{
    margin-top: 5vh;
    color: #ffffff;
    
  }
  #contact-form-ln{
    background-color: #ffffff;
    padding: 0.1rem;
    width:10%;
    margin-left: 45%;
  }
  .form-input{
    margin-top: 1rem;
    padding: 0.5rem;
    width: 80%;
    border: 0.1rem solid #70787e;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0.1rem 0.1rem 0.1rem #5b666f;
  }
  #contact-submit{
    background-color: #b70101;
    padding: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    border: 0.1rem solid #70787e;
    border-radius: 5px;
    box-shadow: 0rem 0.1rem #d6dbdf;
    color: #ffffff;
  }


  /* BRAND TAG LINE */
  .brand-line{
    margin-top:3rem;
    width:80vw;
    margin-left: 10vw;
background-color: #ffffff;
margin-bottom: 3rem;
  }
  #brand-tag-img{
    padding: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    width:12rem;
    height:12rem;
  }
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    footer{
        margin-top:80rem;
        }
        
  /* Header section flex */
  .header-section{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
   /* HEADER SECTION */
   .header-content{
    width: 100vw;
    }
     /*  CONTACT FORM */
  .contact-form{
    width:88%;
    margin-left: 6%;
 /*   height: 56vh;
    margin-top: 6vh;
    background-color: #044680;
    border-radius: 15px;
    box-shadow: .1rem .3rem .2rem #b1b9bf; */
  }
  #nav-icon{
    display:"block";
  }
}
  /* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    footer{
        margin-top: 60rem;
        }
         /* Header section flex */
  .header-section{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
   /* HEADER SECTION */
   .header-content{
    width: 100vw;
    }
       /*  CONTACT FORM */
  .contact-form{
    width:88%;
    margin-left: 6%;
  /*  height: 56vh;
    margin-top: 6vh;
    background-color: #044680;
    border-radius: 15px;
    box-shadow: .1rem .3rem .2rem #b1b9bf; */
  }
  #nav-icon{
    display:"block";
  }


}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    footer{
        margin-top: 30rem;
        }
         /* Header section flex */
  .header-section{
    display: flex;
    flex-direction: row;
    width: 100%;
  }
     /* HEADER SECTION */
     .header-content{
        width: 60vw;
        }
               /*  CONTACT FORM */
  .contact-form{
    width:28%;
    margin-left: 6%;
  /*  height: 56vh;
    margin-top: 6vh;
    background-color: #044680;
    border-radius: 15px;
    box-shadow: .1rem .3rem .2rem #b1b9bf; */
  }
  #nav-icon{
    display:"block";
  }
}

  /* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    footer{
    margin-top: 15rem;
    }
    /* Header section flex */
    .header-section{
        display: flex;
        flex-direction: row;
        width: 100%;
      }
         /* HEADER SECTION */
   .header-content{
    width: 60vw;
    }
           /*  CONTACT FORM */
  .contact-form{
    width:28%;
    margin-left: 6%;
  /*  height: 56vh;
    margin-top: 6vh;
    background-color: #044680;
    border-radius: 15px;
    box-shadow: .1rem .3rem .2rem #b1b9bf; */
  }
}



/* HEADER IMAGE */
#header-img{
  /*animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;*/
}

@keyframes example {
 0%{ margin-top: 5rem;}
 
}
/* Header Get Consultancy Btn */
#gt-consult{
background-color: #ffffff;
border: 0.1rem solid#80bfff;
box-shadow: 1px 2px 2px 1px #cccccc;
padding:0.7rem;
border-radius: 5px;
font-size: 1.2rem;
font-weight: 600;
}
#gt-consult:hover{
  background-color:#0268cd;
  color:#dfdfdfff
}
/* Contact Form Display */
#ct-form{
  display: none;
}


/* NAVBAR COLLAPSE */
#ext-nav-content{
  background-color:#fffefe;
  padding:2rem;
  border:0.2rem solid #a4a2a2;
  z-index: 3;
}
#nav-icon{
  display:"none";
}