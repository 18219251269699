
/* FinalChanges to css */

/* Home Page Header */
.home-header-pd{
    padding:2rem;
  }

  .home-header-h1{
    font-weight:bold;
    font-size:3.2rem;
    color:#0b6674;
  }
  .home-header-p1{
    font-size:1.4rem;
    margin-top:2rem;
  }
  .home-header-p2{
    font-size:1.4rem;
    font-weight:bold;
  }
  .btn-lft-margin{
    margin-left: 2rem;
  }

  .serviceshome-btn-container{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  /* Logo Responsive */
  .text-logo-resp{
    font-family: Open Sans, sans-serif;
    font-weight: bold;
    font-size:1.4rem;
    margin-left:0;
  }
 /*} #home2-navbar-logo{

  }

  /*#ip-h2{
    font-weight:bold;
    font-size:3.2rem;
  }
  .pricing-ul-text{
   font-size:2rem;
  }
  */

  /* Whatsapp Chat Icon */
.wtp-chat-container{
    position:fixed;
    margin-top:92vh;
    margin-left:94vw;

}
.wtp-chat-icon{
   height:3rem;
   width:3rem;
   
}


  /* EXTRA RESPONSIVENESS START */
/* minimum width 720 and maximum width 800 */
@media (min-width:720px) and (max-width:800px){

    
    /* Home Page Header */  
    .home-header-h1{
        font-size:2.8rem;
      }
      .home-header-p1{
        font-size:1.2rem;
      }
      .home-header-p2{
        font-size:1.2rem;
      }

    /* Services home */
   .sv-header-left h1{
    font-size: 2.8rem;
    }
    .sv-header-left p{
    font-size: 1.2rem;
    }

    /* Btools Home */  
   .bth-images-section-top h2{
    font-size: 2.7rem;
    }
   .bth-images-section-top p{
    font-size: 1.2rem;
    }

     /* Whatsapp Chat Icon */
.wtp-chat-container{
    position:fixed;
    margin-top:92vh;
    margin-left:92vw;
}
.wtp-chat-icon{
   height:3rem;
   width:3rem;
}

#ip-h2{
  font-size:2rem;
}
.pricing-ul-text{
  font-size:1.6rem;
 }
   
}


/* minimum width 600 and maximum width 720 */
@media (min-width:600px) and (max-width:720px){

    
    /* Home Page Header */  
    .home-header-h1{
        font-size:2.6rem;
      }
      .home-header-p1{
        font-size:1.2rem;
      }
      .home-header-p2{
        font-size:1.2rem;
      }


      /* Services home */
   .sv-header-left h1{
    font-size: 2.6rem;
    }
    .sv-header-left p{
    font-size: 1.2rem;
    }
    .sv-services-section h2{
        font-size: 2.6rem;
    }

    /* Btools Home */  
   .bth-images-section-top h2{
    font-size: 2.5rem;
    }
   .bth-images-section-top p{
    font-size: 1.2rem;
    }

    .home-header-pd{
        padding:1.6rem;
    }

    .home2-services-fcard-right h3{
    font-size: 1.2rem;
    }
    .home2-services-fcard-right h5{
     font-size: 1rem;
    }

    .btn-lft-margin{
        margin-left: 1.4rem;
      }
   
/* Whatsapp Chat Icon */
.wtp-chat-container{
    position:fixed;
    margin-top:92vh;
    margin-left:90vw;
}
.wtp-chat-icon{
   height:3rem;
   width:3rem;
}

#ip-h2{
  font-size:1.8rem;
  text-align: center;
}
.pricing-ul-text{
  font-size:1.4rem;
 }

}

 /* minimum width 480 and maximum width 600 */
 @media (min-width:480px) and (max-width:600px){


      /* Logo Responsive */
  .text-logo-resp{
    font-family: Open Sans, sans-serif;
    font-weight: bold;
    font-size:1.2rem;
    margin-left:0;
  }
  #home2-navbar-logo{
     height:2.6rem;
     width:2.6rem;
  }

    
    /* Home Page Header */  
    .home-header-h1{
        font-size:2.2rem;
      }
      .home-header-p1{
        font-size:1rem;
      }
      .home-header-p2{
        font-size:1rem;
      }

     /* Services home */
    .sv-header-left h1{
    font-size: 2.2rem;
    }
    .sv-header-left p{
    font-size: 1rem;
    }
    .sv-services-section h2{
        font-size: 2.2rem;
    }
    /* Btools Home */  
   .bth-images-section-top h2{
    font-size: 2.1rem;
    }
   .bth-images-section-top p{
    font-size: 1rem;
    }

    .home2-services-fcard-right h3{
    font-size: 1.2rem;
    }
    .home2-services-fcard-right h5{
    font-size: 1rem;
    }

    .home-header-pd{
        padding:1.4rem;
      }

      .btn-lft-margin{
        margin-left: 0;
      }

      .serviceshome-btn-container{
        display: flex;
        flex-direction: column;
      }

      /* Features Section */
      .bth-features-section{
        display: flex;
        flex-direction: column;
        padding: 2rem;
        }
        .bth-features-left{
            width:100%;
            text-align: left;
            padding: 2rem;
        }
        .bth-features-right{
            width:100%;
        }
        .bth-features-left h2{
            font-size: 2.6rem;
        }
        .bth-features-left p{
            font-size: 1.2rem;
        }
        .bth-features-elm-section{
            display: flex;
            flex-direction: column;
        }
        .bth-features-elm-section-1{
            width:100%;
        }
        .bth-features-elm-section-2{
            width:100%;
        }
        
    
        
        /* Whatsapp Chat Icon */
.wtp-chat-container{
    position:fixed;
    margin-top:92vh;
    margin-left:86vw;
}
.wtp-chat-icon{
   height:2.6rem;
   width:2.6rem;
}

#ip-h2{
  font-size:1.8rem;
  text-align: center;
}
.pricing-ul-text{
  font-size:1.4rem;
 }
}

  /* minimum width 360 and maximum width 480 */
  @media (min-width:360px) and (max-width:480px){

   /* Logo Responsive */
  .text-logo-resp{
    font-family: Open Sans, sans-serif;
    font-weight: bold;
    font-size:1rem;
    margin-left:0;
  }
  #home2-navbar-logo{
     height:2rem;
     width:2rem;
  }

    
    
    /* Home Page Header */  
    .home-header-h1{
        font-size:1.6rem;
      }
      .home-header-p1{
        font-size:0.9rem;
      }
      .home-header-p2{
        font-size:0.9rem;
      }


       /* Services home */
   .sv-header-left h1{
    font-size: 1.6rem;
    }
    .sv-header-left p{
    font-size: 0.9rem;
    }
    .sv-services-section h2{
        font-size: 1.6rem;
    }
    /* Btools Home */  
   .bth-images-section-top h2{
    font-size: 1.5rem;
    }
   .bth-images-section-top p{
    font-size: 0.9rem;
    }

    .home2-services-fcard-right h3{
    font-size: 1.2rem;
    }
    .home2-services-fcard-right h5{
    font-size: 1rem;
    }

    .home-header-pd{
        padding:1rem;
      }

      .btn-lft-margin{
        margin-left: 0;
      }
      .serviceshome-btn-container{
        display: flex;
        flex-direction: column;
      }

      
      /* Features Section */
      .bth-features-section{
        display: flex;
        flex-direction: column;
        padding: 1rem;
        }
        .bth-features-left{
            width:100%;
            text-align: left;
            padding: 1rem;
        }
        .bth-features-right{
            width:100%;
        }
        .bth-features-left h2{
            font-size: 2.2rem;
        }
        .bth-features-left p{
            font-size: 1rem;
        }
        .bth-features-elm-section{
            display: flex;
            flex-direction: column;
        }
        .bth-features-elm-section-1{
            width:100%;
        }
        .bth-features-elm-section-2{
            width:100%;
        }
        
/* Whatsapp Chat Icon */
.wtp-chat-container{
    position:fixed;
    margin-top:92vh;
    margin-left:86vw;
}
.wtp-chat-icon{
   height:2.4rem;
   width:2.4rem;
}

#ip-h2{
  font-size:1.6rem;
  text-align: center;
}
.pricing-ul-text{
  font-size:1rem;
 }
   
}

 /* minimum width 240 and maximum width 360 */
 @media (min-width:240px) and (max-width:360px){

  /* Logo Responsive */
  .text-logo-resp{
    font-family: Open Sans, sans-serif;
    font-weight: bold;
    font-size:0.9rem;
    margin-left:0;
  }
  #home2-navbar-logo{
     height:1.6rem;
     width:1.6rem;
  }


    /* Home Page Header */  
    .home-header-h1{
        font-size:1.6rem;
      }
      .home-header-p1{
        font-size:0.9rem;
      }
      .home-header-p2{
        font-size:0.9rem;
      }

     /* Services home */
     .sv-header-left h1{
     font-size: 1.6rem;
     }
     .sv-header-left p{
      font-size: 0.9rem;
     }
     .sv-services-section h2{
        font-size: 1.6rem;
    }
     /* Btools Home */  
   .bth-images-section-top h2{
    font-size: 1.5rem;
    }
   .bth-images-section-top p{
    font-size: 0.9rem;
    }

     .home2-services-fcard-right h3{
      font-size: 1.2rem;
     }
      .home2-services-fcard-right h5{
      font-size: 1rem;
     }

    .home-header-pd{
        padding:0.6rem;
      }

      .btn-lft-margin{
        margin-left: 0;
      }

      .serviceshome-btn-container{
        display: flex;
        flex-direction: column;
      }
   
      
      /* Features Section */
      .bth-features-section{
        display: flex;
        flex-direction: column;
        padding: 1rem;
        }
        .bth-features-left{
            width:100%;
            text-align: left;
            padding: 1rem;
        }
        .bth-features-right{
            width:100%;
        }
        .bth-features-left h2{
            font-size: 1.6rem;
        }
        .bth-features-left p{
            font-size: 0.9rem;
        }
        .bth-features-elm-section{
            display: flex;
            flex-direction: column;
        }
        .bth-features-elm-section-1{
            width:100%;
        }
        .bth-features-elm-section-2{
            width:100%;
        }

        
/* Whatsapp Chat Icon */
.wtp-chat-container{
    position:fixed;
    margin-top:94vh;
    margin-left:86vw;
}
.wtp-chat-icon{
   height:2.2rem;
   width:2.2rem;
}
        
#ip-h2{
  font-size:1.4rem;
  text-align: center;
}
.pricing-ul-text{
  font-size:1rem;
 }
}

 /* minimum width 120 and maximum width 240 */
 @media (min-width:120px) and (max-width:240px){


     /* Logo Responsive */
  .text-logo-resp{
    font-family: Open Sans, sans-serif;
    font-weight: bold;
    font-size:0.8rem;
    margin-left:0;
  }
  #home2-navbar-logo{
     height:1.2rem;
     width:1.2rem;
  }


    
    /* Home Page Header */  
    .home-header-h1{
        font-size:1.4rem;
      }
      .home-header-p1{
        font-size:0.8rem;
      }
      .home-header-p2{
        font-size:0.8rem;
      }

    .sv-header-left h1{
        font-size: 1.4rem;
    }
    .sv-header-left p{
        font-size: 0.8rem;
    }
    .sv-services-section h2{
        font-size: 1.4rem;
    }
    /* Btools Home */  
   .bth-images-section-top h2{
    font-size: 1.3rem;
    }
   .bth-images-section-top p{
    font-size: 0.8rem;
    }

    .home-header-pd{
        padding:0.2rem;
      }

      .btn-lft-margin{
        margin-left: 0;
      }

      .serviceshome-btn-container{
        display: flex;
        flex-direction: column;
      }

        /* Features Section */
        .bth-features-section{
            display: flex;
            flex-direction: column;
            padding: 1rem;
            }
            .bth-features-left{
                width:100%;
                text-align: left;
                padding: 1rem;
            }
            .bth-features-right{
                width:100%;
            }
            .bth-features-left h2{
                font-size: 1.6rem;
            }
            .bth-features-left p{
                font-size: 0.9rem;
            }
            .bth-features-elm-section{
                display: flex;
                flex-direction: column;
            }
            .bth-features-elm-section-1{
                width:100%;
            }
            .bth-features-elm-section-2{
                width:100%;
            }
   

               
/* Whatsapp Chat Icon */
.wtp-chat-container{
    position:fixed;
    margin-top:94vh;
    margin-left:78vw;
}
.wtp-chat-icon{
   height:2rem;
   width:2rem;
}

#ip-h2{
  font-size:1.4rem;
  text-align: center;
}
.pricing-ul-text{
  font-size:0.8rem;
 }
}