.table {
    font-size: 1rem;
    
  }
  
  .table th, .table td {
    vertical-align: middle;
  }
  
  .table th {
    font-weight: bold;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .table-bordered th, .table-bordered td {
    border: 1px solid #dee2e6;
  }
  
  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .table th, .table td {
    white-space: nowrap; /* Prevent text wrapping */
  }
  
  .table th:first-child, .table td:first-child {
    width: 150px; /* Fixed width for the first column */
  }

  .td-design{
    
  }
  .table tbody tr {
    padding-bottom: 15px; /* Adjust the value as needed */
  }