
/**************************** MEDIUM PC VIEW CHANGES ********************************/
@media(max-width:1200px){

    /* NAVBAR */
    /* navbar logo */
    #hire-navbar-logo-default{
    margin-top:0.1rem;
    height:6rem;
    width:6rem;
    }
    /*menu icon */
    #navbar-menu-icon{
    margin-left: 4rem;
    margin-top:1rem;
    height:3rem;
    width:3rem;
    }
    
    
/* MAIN SECTION */
.hire-main-section{
    display: flex;
    flex-direction: right;
    width: 100vw;
    font-size: 72%;
}
/* LEFT SIDEBAR */
.hire-left-sidebar{
    width:23vw;
    box-shadow: 2px 1px 2px #777c86;
    height: 100vh;
}
/* MAIN PANEL */
.hire-main-panel{
    width:54vw;
    background-color: #fafafa;
    height: 2000vh;
    overflow-y: scroll;
    scroll-behavior: auto;
}
/* MAIN PANEL EXT */
.hire-main-panel-ext{
    width:77vw;
    background-color: #fafafa;
    height: 2000vh;
    overflow-y: scroll;
    margin-top: 9vh;
}
/* RIGHT SIDEBAR */
.hire-right-sidebar{
    width:23vw;
    box-shadow: 2px 1px 2px #777c86;
    height: 100vh;
}



/*  MAIN PANEL */
/* SEARCHBAR */
.hire-search-bar{
    width: 96%;
    height: 2vh;
    margin-left: 2%;
    }
    
    /* LIST PANEL */
    .list-panel{
        width:96%;
        height:100%;
        margin-left: 2%;
        border: 0.1 rem solid #013cb1;
        border-radius: 10px;
        background-color: #ffffff;
        margin-top: 2rem;
        box-shadow: 2px 1px 2px #777c86;
        overflow: scroll;
        overflow-y: scroll;
    }
    
    /* Hire List */
    .hire-list{
        margin-top: 2vh;
    }
    /* Hire List Card */
    .hire-list-card{
        width:94%;
        margin-left: 3%;
        border: 0.1 rem solid #013cb1;
        border-radius: 10px;
        margin-top: 2rem;
        box-shadow: 1px 1px 2px 1px #021336;
        text-align: left;
        
    }
    .hire-list-card-top{
        margin-left: 2rem;
        display:flex;
        flex-direction: row;
    }
    .hire-list-card-top-l{
        margin-left: 1rem;
        width: 50%;
    
    }
    .hire-list-card-top-r{
        margin-right:1rem;
        font-size: 90%;
        text-align: right;
        width: 50%;
    }
    .hire-list-card-area{
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .hire-list-card-area-l{
        width:70%;
        margin-bottom: 1rem;
    }
    .hire-list-card-area-r{
        width:30%;
        margin-bottom: 1rem;
    }
    .hire-list-card-salarycard{
        margin-bottom: 1rem;
        margin-left: 4rem;
        font-size: 80%;
        color:#8c8b94;
    }
    .hire-list-card-btn-list{
        margin-left: 3rem;
        display: flex;
        flex-direction: row;
    }
    .hire-list-card-function{
        color:#777c86;
        font-size: 80%;
        text-align: center;
        margin-right: 1rem;
        width:9rem;
        margin-left: 1rem;
        padding: 0.3rem;
        margin-top: 1rem;
    }
    .hire-list-card-function-component{
        display:flex;
        flex-direction:column;
        align-items: center;
    }
    .hire-list-card-function-component-l{
        width: 40%;
    }
    .hire-list-card-function-component-r{
        width: 60%;
        text-align: center;
    }
    .hire-list-card-function-component button{
        background-color: transparent;
        border: none;
    }
    .hire-list-card-function-component img{
        width:3rem;
        height:3rem;
    }
    .hire-list-card-badge{
        color:#777c86;
        font-size: 80%;
        text-align: center;
        margin-right: 1rem;
        background-color: #e1e1e2;
        width:70%;
        margin-left: 15%;
        padding: 0.3rem;
        border: 0.1 rem solid #949698;
        border-radius: 5px;
        margin-top: 1rem;
        box-shadow: 1px 1px 1px 1px #a4a4a4;
    }
    .hire-list-card-badge-component{
        display:flex;
        flex-direction:row;
        align-items: center;
    }
    .hire-list-card-badge-component-l{
        width: 40%;
    }
    .hire-list-card-badge-component-r{
        width: 60%;
        text-align: left;
    }
    .hire-list-card-badge-component img{
        width:1.5rem;
        height:1.5rem;
    }
    .hire-list-card-skillbar{
        width: 94%;
        margin-left: 3%;
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
    }
    .hire-card-skill-badge{
        color:#061d4a;
        font-weight: bold;
        font-size: 80%;
        text-align: center;
        margin-right: 0.5rem;
        background-color: #a1bcff;
        width:10rem;
        margin-left: 0.5rem;
        padding: 0.3rem;
        border: 0.1 rem solid #103961;
        border-radius: 5px;
        margin-top: 1rem;
        box-shadow: 1px 1px 1px 1px #a4a4a4;
    }
    .hire-list-card-bottom{
        margin-top: 1rem;
        font-size: 75%;
        margin-right: 2rem;
        color:#6f7275;
        text-align: right;
    }
    
    
/* FORM PANEL */
.hire-form-container{
    font-size: 80%;
}
/* Form Elements */
.hire-form-list{
    display: flex;
    flex-direction: row;
}
.hire-form-group-large{
    width:94%;
    margin-left: 1%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
}
.hire-form-group-large .hire-form-group-left{
    width:16%;
    text-align: left;
}
.hire-form-group-large .hire-form-group-right{
    width:82%;
}
.hire-form-group-small{
    width: 45%;
    margin-left: 0%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
}


    /* BUTTONS */
    .hire-btn-default{
        margin-left: 1rem;
        padding:1rem;
        background-color: #013cb1;
        border-radius: 10px;
        font-weight: bold;
        border:0.1rem solid #ffffff;
        color:#ffffff;
        margin-top: 1rem;
        box-shadow: 1px 1px 1px 1px #a4a4a4;
    
    }

    }   