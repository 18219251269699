

/* SETTING CSS PROPERTIES FOR SECTIONS */

#section-1{
display: block;
}

/* Other Sections */
#section-2,#section-3,#section-4,#section-5,#section-6,#section-7,#section-8,#section-9,#section-10{
    display: none;
}