
#tc-img{
    height:43vh;
    width:100%;
}
.tc-ins{
    display: flex;
    width:100%;
    text-align: left;
}
.tc-ins-disp{
    width:50%;
}


@media (min-width:1160px) and (max-width:1240px){

   #tc1-head{
     font-size: 1.6rem;
   }
   #tc-head{
    font-size: 1.4rem;
   }

}


@media (min-width:1020px) and (max-width:1160px){

    #tc1-head{
      font-size: 1.4rem;
    }
    #tc-head{
     font-size: 1.2rem;
    }
 
 }

 
@media (min-width:900px) and (max-width:1020px){

    #tc1-head{
     font-size: 1.4rem;
    }
    #tc-head{
     font-size: 1.2rem;
    }
 
 }

 
@media (min-width:740px) and (max-width:900px){

    
    #tc-img{
    height:34vh;
    }
    #tc1-head{
     font-size: 1.4rem;
    }
    #tc-head{
     font-size: 1.2rem;
    }
 
 }

 
@media (min-width:600px) and (max-width:740px){

        
    #tc-img{
     height:34vh;
    }
    #tc1-head{
     font-size: 1.2rem;
    }
    #tc-head{
     font-size: 1rem;
    }
 
 }

 
@media (min-width:400px) and (max-width:600px){

          
    #tc-img{
      height:34vh;
    }
    #resp-5{
      width: 100%;
    }  
    #tc1-head{
     font-size: 1.2rem;
    }
    #tc-head{
     font-size: 1rem;
    }
    .tc-ins{
        display: flex;
        flex-direction: column;
    }
    .tc-ins-disp{
        width:100%;
    }
 }

 
@media (min-width:200px) and (max-width:400px){
      
    #tc-img{
      height:34vh;
    }
    #resp-5{
      width: 100%;
    }       
    #tc1-head{
     font-size: 1.2rem;
    }
    #tc-head{
     font-size: 1rem;
    }
    .tc-ins{
        display: flex;
        flex-direction: column;
    }
    .tc-ins-disp{
        width:100%;
    }
 }

 
@media (min-width:00px) and (max-width:200px){

    #tc-img{
        height:25vh;
      }
    #resp-5{
        width: 100%;
    }       
    #tc1-head{
     font-size: 1rem;
    }
    #tc-head{
     font-size: 0.8rem;
    }
    .tc-ins{
        display: flex;
        flex-direction: column;
    }
    .tc-ins-disp{
        width:100%;
    }
 }