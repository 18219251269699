  /*Setting Cards display in one line */
@media (min-width:960px) and (max-width:1300px){

    #resp-3{
     width:33.33%;
    }
    #resp-2{
        width:50%;
       }


}
@media (min-width:560px) and (max-width:960px){

    #resp-3{
     width:50%;
    }
    #resp-2{
        width:100%;
       }
}

/* For Top Headung and paragraph font size 900 and above */
@media (min-width:1240px) and (max-width:1320px){

  .sv-header-left h1{
       font-size: 3rem;
  }
  .sv-header-left p{
    font-size: 1.4rem;
}

}

@media (min-width:1120px) and (max-width:1240px){

  .sv-header-left h1{
       font-size: 2.6rem;
  }
  .sv-header-left p{
    font-size: 1.2rem;
}

}

@media (min-width:1000px) and (max-width:1120px){

  .sv-header-left h1{
       font-size: 2.4rem;
  }
  .sv-header-left p{
    font-size: 1rem;
}

}

@media (min-width:900px) and (max-width:1000px){

  .sv-header-left h1{
       font-size: 2rem;
  }
  .sv-header-left p{
    font-size: 1rem;
}

}