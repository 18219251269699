.home2-footer{
    margin-top: 3rem;
    width:100vw;
    background-color: #000000;
    height: 70vh;
}
.home2-footer-container{
   display: flex;
   flex-direction: row;
   background-color: #000000;
}
.home2-footer-section1{
    padding: 3rem;
    width:50%;
    
}
.home2-footer-section2{
    padding: 3rem;
    width: 50%;
}
.home2-footer-list-section{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.home2-footer-section1-list{
    width:50%;
}
.home2-footer-section1-list h3{  
    margin-left: 2rem;
    text-align: justify;
    font-family: 'Open Sans', sans-serif;
    color:#fbdd1d;
}
.home2-footer-section1-list ul{ 
    text-align: justify;
    list-style-type: none; 
    font-family: 'Open Sans', sans-serif;
    color:#ffffff;
}
.home2-footer-sociallinks-bar{
    border-bottom: 0.1rem solid #ffffff;
}
.home2-footer-sociallinks-bar ul{
    list-style-type: none;
    align-items: right;
}
.home2-footer-contact-form{
    padding: 2rem;
    background-color: rgb(245, 0, 0);
    border-radius: 12px;
    text-align: left;
    color: #ffffff;
    font-weight: bold;
}
.home2-footer-contact-form h2{
  color:#ffffff;
  text-align: left;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
}
.home2-footer-down{
  border-top: 0.1rem solid #ffffff;
  background-color: #000000;
  color: #ffffff;
  padding: 1rem;
}

/* Width 600px and 740px */
@media (min-width:600px) and (max-width:740px){
    
    .home2-footer-container{
        display: flex;
        flex-direction: column;
     }
     .home2-footer-section1{
      width: 100%;
     }
     .home2-footer-section2{
      width: 100%;
     }
}

/* Width 480px and 600px */
@media (min-width:480px) and (max-width:600px){

    .home2-footer-container{
        display: flex;
        flex-direction: column;
     }
     .home2-footer-section1{
      width: 100%;
     }
     .home2-footer-section2{
      width: 100%;
     }

}

/* Width 360px and 480px */
@media (min-width:360px) and (max-width:480px){

    .home2-footer-container{
        display: flex;
        flex-direction: column;
     }
     .home2-footer-section1{
      width: 100%;
     }
     .home2-footer-section2{
      width: 100%;
     }

}

/* Width 240px and 360px */
@media (min-width:240px) and (max-width:360px){

    .home2-footer-container{
        display: flex;
        flex-direction: column;
     }
     .home2-footer-section1{
      width: 100%;
     }
     .home2-footer-section2{
      width: 100%;
     }

}

