
.lg-item{
    font-size:1.4rem;
}
.li-item{
    font-size:1rem;
}

@media (min-width:1240px) and (max-width:1340px){

    #home2-navbar-logo{
        height:2rem;
        width:2rem;
       }

    .lg-item{
        font-size:1rem;
    }
    .li-item{
        font-size:0.9rem;
    }
    #home2-navbar-login{
        font-size:0.9rem;
        padding: .5rem;
    }

}


@media (min-width:1200px) and (max-width:1240px){

    #home2-navbar-logo{
        height:2rem;
        width:2rem;
       }

    .lg-item{
        font-size:0.8rem;
    }
    .li-item{
        font-size:0.9rem;
    }

}


@media (min-width:1100px) and (max-width:1200px){

    #home2-navbar-logo{
        height:2rem;
        width:2rem;
       }

    .lg-item{
       display: none;
    }
    .li-item{
        font-size:0.8rem;
    }

}



@media (min-width:1020px) and (max-width:1100px){

    #home2-navbar-logo{
        height:2rem;
        width:2rem;
       }

    .lg-item{
       display: none;
    }
    .li-item{
        font-size:0.8rem;
    }

}


@media (min-width:980px) and (max-width:1020px){

    #home2-navbar-logo{
        height:2rem;
        width:2rem;
       }

    .lg-item{
       display: none;
    }
    .li-item{
        font-size:0.7rem;
    }

}